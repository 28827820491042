import styled from "@emotion/styled";
import {colors} from "../UI";

export const Container = styled.div`
    position:relative;
    width:100%;
    min-height:100%;
    background:#d1d1d1;
    background: linear-gradient(rgba(232, 233, 237, 0.96), rgba(232, 233, 237, 0.96)), 
    url("/img/fondo_pagar.png"), none, none;
    hr{
        margin-top: 1rem;
        margin-bottom: 1rem;
        border: 0;
        border-top: 0.1px solid ${colors.grey01};
    };
   
    background-position: center; 
    background-size: cover;
    
    .contHeader{
        width:100%;
        
        @media all and (min-width:900px){
            margin:auto;
            margin-bottom:-20px;
            max-width:1280px; 
        }
        
         @media all and (min-width:768px){
            padding:24px 24px 0;
        }
         
         @media all and (min-width: 300px){
            padding:24px 24px 0;
        }
    }
    
    .card{
        max-width:560px;
        margin:auto;
        padding: 20px;
        border-radius:10px;
        padding-bottom:40px;
        
        @media all and (min-width: 540px){
            padding: 40px;
            padding-bottom:50px;
        }
    }
    
    .action{
        position:absolute;
        bottom:0;
        left:0;
        transform:translateY(20px);
    }
    
    .title{
        margin-bottom:30px;
        font-size:22px;
        font-weight:bold;
        text-align:left;
        width:100%;
        
        @media all and (max-width:500px){
            font-size:18px;
            line-height:1.3em;
        }
    }
    
     .title2{
        margin-bottom:30px;
        font-size:30px;
        font-weight:bold;
        
        @media all and (max-width:500px){
            font-size:22px;
        }
    }
    
    .label-text{
        font-size: 16px;
        color: #343537;
        font-weight: 500;
        text-align: left;
        width: 100%;
        font-family: Roboto;
        margin-bottom: 3px;
    }
    
    .text-label{
        font-size: 16px;
        -webkit-flex: 10 0 70%;
        -ms-flex: 10 0 70%;
        flex: 10 0 70%;
        background-color: transparent;
        color: #000000;
        cursor: not-allowed;
        border-radius: 0;
        margin-top: none;
        width: 100%;
        border: none;
        font-weight: 300;
        font-family: Roboto;
        text-align: left;
        margin-top: 2px;
    }
    
`;