import {Document, Page, View, Image, Text, StyleSheet, Font} from "@react-pdf/renderer";
import styled from "@react-pdf/styled-components";
import React from "react";
import {colors} from "../UI";
import {getCookie} from "../../helper";
import RobotoCondensedBold from '../../fonts/RobotoCondensed-Bold.ttf';
import RobotoCondensedRegular from '../../fonts/RobotoCondensed-Regular.ttf';


const Ccontainer = styled.View`
    padding:10pt 40pt 20pt;
    width:100%;
    margin:auto;
    height:100%;
    font-family: 'rRegular';
    justify-content:flex-start;
`;

const Flex = styled.View`
    width:100%;
    display:flex;
    flex-flow:${props => props.direction ? props.direction : "row"} wrap;
    justify-content: ${props => props.jc};
    align-items:center;
    flex:${props => props.flex};
    align-content:flex-start;
`;

const Canal = (canal) => {
    switch (canal) {
        case "WP":
            return "Página Web DaviPlata";
        case "WAPP":
            return "Web App DaviPlata";
        case "APP":
            return "App DaviPlata";
        default:
            return canal;
    }
};

const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
    },
    image: {
        flex: "0 0 300px",
        width: "20%"
    },
    viewLogo: {
        flex: "0 0 50",
        width: "181px",
        height: "92px",
        position: 'relative',
        overflow: 'hidden'
    },
    logoDaviplata: {
        width: "181px",
        height: "92px",
        position: "absolute",
        left: "-35px",
        bottom: "15px"
    },
    title: {
        textAlign: "center",
        fontSize: "16pt",
        padding: "20pt 0 10pt"
    },
    titleR: {
        textAlign: "center",
        color: colors.rojo01,
        padding: "20pt 0 10pt"
    },
    tabla: {
        borderTop: `2pt solid ${colors.rojo01}`,
        borderBottom: `2pt solid ${colors.rojo01}`,
        padding: "10px 0"
    },
    itemNV: {
        fontSize: "12pt",
        padding: "5pt 0"
    },
    itemHijos: {
        fontWeight: "bold"
    },
    textFooter: {
        fontSize: "7pt",
        textAlign: "center"
    },
    vigilado: {
        position: "absolute",
        left: "15pt",
        bottom: "100pt",
        width: "50pt"
    },
    VigiladoImg: {
        width: "15pt",
        height: "auto"
    },
    bold: {
        fontFamily: "rBold"
    },
    small: {
        fontSize: "12pt"
    },
    textRight: {
        position: "absolute",
        right: "-50pt",
        top: "100pt",
        transform: "rotate(-90deg)",
        fontSize: "8pt"
    }
});


Font.register({
    family: 'rBold',
    src: RobotoCondensedBold,
});

Font.register({
    family: 'rRegular',
    src: RobotoCondensedRegular,
});

const ItemNV = ({name, value}) => {
    return (
        <Flex jc={"center"} flex={"1 0 auto"} style={styles.itemNV}>
            <Flex jc={"flex-start"} flex={"1 0 200px"} style={styles.bold}>
                <Text>{name}</Text>
            </Flex>
            <Flex jc={"flex-end"} flex={"1 0 100px"}>
                <Text>{value}</Text>
            </Flex>
        </Flex>
    )
};


export const PDF = ({
                        monto,
                        numero,
                        referencia,
                        fecha,
                        costo,
                        transaccion,
                        cus,
                        motivo,
                        nAprovacion,
                        direcionIp,
                        docNumber,
                        docType
                    }) =>
    <Document>
        <Page size="A4">
            <Ccontainer>
                <Flex flex={"0 0 50px"} jc={"space-between"}>
                    <View style={styles.viewLogo}>
                        <Image style={styles.logoDaviplata}
                               src={`${process.env.REACT_APP_BASE_MULTIMEDIA}/PDF/daviplata.png`}/>
                    </View>
                </Flex>

                <Flex jc={"flex-end"} flex={"0 0 50px"}>
                    <Text style={styles.small}>Fecha transacción: {fecha}</Text>
                </Flex>

                <Flex flex={"0 0 40px"} style={styles.bold}>
                    <Text style={styles.titleR}>RECARGAS PSE</Text>
                </Flex>
                <Flex jc={"flex-start"} direction={"column"}>
                    <Flex style={styles.tabla} flex={"0 0 auto"}>
                        <ItemNV name={"Resultado de la transacción:"} value={transaccion}/>
                        <ItemNV name={"Código único CUS:"} value={cus}/>
                        <ItemNV name={"Producto destino:"} value={numero}/>
                        <ItemNV name={"Canal"} value={Canal(getCookie("canal"))}/>
                        <ItemNV name={"No. aprobación"} value={nAprovacion}/>
                        <ItemNV name={"Dirección IP:"} value={direcionIp}/>
                        <ItemNV name={"Valor transacción:"} value={monto}/>
                        <ItemNV name={"Costo transacción:"} value={costo}/>
                    </Flex>
                </Flex>

                <Flex flex={"0 0 auto"}>
                    <Text style={styles.textFooter}>
                        DaviPlata es un depósito de dinero electrónico amparado por el seguro de depósito Fogafin.
                        Consulte condiciones, reglamento, tarifas y más información en: www.daviplata.com. Para
                        cualquier diferencia con el saldo, comuníquese a través del botón ¿Necesita Ayuda? de la
                        aplicación o al #688 . Si requiere, puede comunicarse con nuestro Defensor del Consumidor
                        Financiero: Carlos Mario Serna, Calle 72 No. 6-30, piso 18, Bogotá, teléfono 609-2013, fax
                        482-9715, correo electrónico: defensordelcliente@davivienda.com.
                    </Text>
                    <Text style={styles.textFooter}>
                        Revisoria fiscal: KPMG Ltda. Apartado 77859,Bogotá
                    </Text>
                </Flex>

                <View style={styles.vigilado}>
                    <Image src={`${process.env.REACT_APP_BASE_MULTIMEDIA}/PDF/vigilado.png`}
                           style={styles.VigiladoImg}/>
                </View>

                <View style={styles.textRight}>
                    <Text>
                        Banco Davivienda S.A. NIT. 860.034.313-7
                    </Text>
                </View>
            </Ccontainer>
        </Page>
    </Document>
;

