import React, {useEffect, useState} from "react";
import styled from "@emotion/styled";
import ResponseDetail from "./responsDetail";
import Fallida from "./fallida";
import Header from "./../header";
import Flex from "../UI/Flex";
import {bool} from "yup";
import axios from "axios";
import {getCookie, getToken} from "../../helper";

const Container = styled(Flex)`
    position:relative;
    min-height:100vh;
    background:#f1f1f1;
`;


function Respuesta() {

    const [trx, SetT] = useState({
        loading: true
    });

    const publiKey = process.env.REACT_APP_PUBLIC_KEY;

    let consultaPSE = async () => {

        let transactionID = getCookie("transactionID")
        let token = await getToken(getCookie('dominioRecaudo'));

        let data = {
            "transactionID": transactionID
        };

        axios({
            data: data,
            url: ` ${process.env.REACT_APP_BASE_URL_REST_RECAUDO}/api/apify/payment/pse/transaction`,
            method: "post",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${token}`
            }
        }).then(response => {
            const {documento, tipo_doc} = response.data.data;
            consult(documento, tipo_doc);
        }).catch(error => {
            consult();
        });
    };

    const consult = (docNumber = "", docType = "CC") => {

        let dataResponse = {
            status: bool(),
            data: {},
            message: "",
        };
        let refPayco = getCookie("refPayco");

        let url = process.env.REACT_APP_SECURE + "?public_key=" + publiKey + "&ref_payco=" + refPayco;
        axios.get(url)
            .then(responseTransaction => {
                if (responseTransaction.status === 200 && responseTransaction.data.success) {
                    let data = responseTransaction.data.data;
                    SetT({
                        ...trx,
                        amount: data.x_amount,
                        cus: data.x_approval_code,
                        bank: data.x_bank_name,
                        hola: data.x_cod_transaction_state,
                        currency: data.x_currency_code,
                        idClient: data.x_cust_id_cliente,
                        ip: data.x_customer_ip,
                        description: data.x_description,
                        date: data.x_fecha_transaccion,
                        franchise: data.x_franchise,
                        refPayco: data.x_ref_payco,
                        state: data.x_response,
                        trxId: data.x_transaction_id,
                        comision: getCookie("commission") || 0,
                        number: getCookie("daviplata"),
                        loading: false,
                        fallida: data.x_response === "Fallida" || data.x_response === "Rechazada" || data.x_response === "Abandonada",
                        pendiente: data.x_response === "Pendiente",
                        docType,
                        docNumber
                    });
                } else {
                    dataResponse.status = true;
                    SetT({...trx, fallida: true, loading: false, pendiente: false})
                }
            }).catch(error => {

            SetT({...trx, fallida: true, loading: false, pendiente: false});
        });
    };

    useEffect(() => {
        consultaPSE();
    }, []);


    return (
        <Container direction={"column"}>
            <Header type={"rojo"}/>
            <Flex className={"wc "} flex={"1 0 auto"} direction={"column"}>
                {trx.loading ?
                    null : //Este es el segundo load  <Loading noBg only dark/> :
                    <>
                        {trx.fallida ?
                            <Fallida title={"rechazada"} message={"Intente nuevamente"}/> :
                            trx.pendiente ?
                                <Fallida title={"pendiente"} message={"Estamos evaluando su solicitud."}/>
                                :
                                <ResponseDetail
                                    trx={trx}
                                />

                        }
                    </>
                }
            </Flex>
        </Container>
    )
}

export default React.memo(Respuesta);
