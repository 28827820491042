import styled from "@emotion/styled";
import Flex from "../UI/Flex";
import {colors} from "../UI";

export const Container = styled(Flex)`
    position:relative;
    min-height:100%;
    background:rgba(1,1,1,1);
    
    .row {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;
    }
    
    .column {
      display: flex;
      flex-direction: column;
      flex-basis: 100%;
      flex: 1;
      align-items:center
    }    
    
       
    .title{
        padding: 0 25px 15px;
        font-size:20px;
        font-weight:bold;
        color:#07A033;
       
        span{
            margin-right:15px;
            border:1px solid #07A033;
            width:30px;
            height:30px;
            border-radius:50%;
        }
    }
    
    .card{
        width:100%;
        max-width: 450px;
        background:white;
        border-radius:10px;
        background:white;
        padding:25px 20px;
        position:relative;
        
        .cont-icon{
            font-size:24px;
            margin-right:10px;
            width:30px;
            height:30px;
            border-radius:50%;
            color:#07A033;
            }
        
        .pendiente,.rechazada{
           
            padding:20px 25px;
           
            .tt{
            font-size:20px;
            font-weight:bold;
            color:#07A033;
            margin-bottom:15px;
            }
            
            p{
                font-weight:300;
                font-size:14px;
            }
        }
        
        .rechazada{
            .cont-icon{
                  color: ${colors.rojo01};
            }
            
            .tt{
            color:${colors.rojo01};
            }
        }
        
        .action{
            position:absolute;
            width:100%;
            bottom:0;
            left:0;
            transform:translateY(20px)
        }
        
        .acciones{
            .separator{
                margin:0 15px;
                border-left:1px solid ${colors.grey02};
            }
            button{
                background:transparent;
                font-size:12px;
                padding:5px;
                img{
                  height:16px;
                  width:auto;  
                  display:block;
                  margin-right:15px;
                }
            }
        }
    }
`;

export const ItenValueStl = styled.div`
   padding: 10px 0;
   font-family:'Roboto';
 
   p{
    font-weight:300;
   }
`;

export const TitleCont = styled(Flex)`

        padding: 0;
        font-size:20px;
        font-weight:bold;
        color:${props =>props.color};
            
            i{
                font-size:28px;
            }
       
        .subtext{
            font-weight:300;
            font-size:16px;
            width:100%;
            color:${colors.grey03};
            text-align:left;
        }
`;

export const ButtonBS = styled.div`
    position:absolute;
    width:100%;
    left:0;
    bottom:0;
    transform:translateY(${props=> props.y})
`;

export const ButtonD = styled.div`
    width:${props=>props.w};
    background:transparent;
    cursor:pointer;
    color:black;
`;
