import axios from "axios";
import store from "./store";
import {SET_STATE_GLOBAL} from "./store/actions";
import CryptoJS from "crypto-js";

const secretKeyEncript = process.env.REACT_APP_SECRET_KEY_ENCRIPT

//

export const encryptData = (value) => {

    return CryptoJS.AES.encrypt(value, secretKeyEncript).toString();
}


//ejemplo para insertar cookie
export const setCookie = (cname, value) => {
    let cookieMaxAge = 30
    let d = new Date()
    d.setTime(d.getTime() + cookieMaxAge * 24 * 60 * 60 * 1000)
    let expiration = 'expires=' + d.toUTCString()

    const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(value), secretKeyEncript).toString();

    document.cookie = cname + '=' + ciphertext + ';' + expiration + ';path=/'
}

//ejemplo para obtener

export const getCookie = (cname) => {
    let name = cname + '='
    let ca = document.cookie.split(';')
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i]
        while (c.charAt(0) === ' ') {
            c = c.substring(1)
        }
        if (c.indexOf(name) === 0) {
            const bytes = CryptoJS.AES.decrypt(c.substring(name.length, c.length), secretKeyEncript);

            return JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
        }
    }
    return ''
}

export const removeCookies = () => {
    const cookies = document.cookie.split(";");

    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i];
        const eqPos = cookie.indexOf("=");
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
}

export const getToken = async (dominioConfigurado, banks = false) => {
    let token = "";
    await axios({
        method: 'post',
        data: {
            dominio: dominioConfigurado
        },
        url: process.env.REACT_APP_BASE_URL_REST_RECAUDO + "/api/recaudo/get/token"
    })
        .then(async result => {
            if (result.status === 200) {
                token = result.data.data.token;
                if(banks){
                    GetTBanks(token);
                }
            }
        }).catch(error => {
            if(banks){
                GetTBanks();
            }
        });

    return token
};

export const ProcesarRecargaPago = async (data, type, commission = null, tokenCaptcha = "") => {

    let dominio = window.location.origin;

    if (!data) {
        return ""
    }

    let {
        daviplata,
        value,
        bank,
        email,
    } = data;

    let respuesta = type === "recargar" ? "/meter-plata/respuesta" : (type === "pagar" ? "/pagar/respuesta" : "/recargaD/respuesta");
    if (!commission) commission = type === "recargar" ? "1070" : (type === "pagar") ? "1080" : "1053";

    let body = type === "recargaD" ? "recargar" : type;

    let bodies = {
        recargar: {
            "consulta":
                [
                    {"parametro": "daviplataCellphone", "value": encryptData(daviplata)},
                    {"parametro": "value", "value": encryptData(value)},
                    {"parametro": "bank", "value": encryptData(bank)},
                    {"parametro": "urlResponse", "value": encryptData(`${dominio}${respuesta}`)},
                    {"parametro": "email", "value": encryptData(email)},
                    {"parametro": "commission", "value": encryptData(commission)},
                    {"parametro": "idRequest", "value": ''},
                ],
            "dominio": process.env.REACT_APP_DOMINIO_RECARGAR
        },
        pagar: {
            "consulta":
                [
                    {"parametro": "daviplataCellphone", "value": encryptData(daviplata)},
                    {"parametro": "value", "value": encryptData(value)},
                    {"parametro": "bank", "value": encryptData(bank)},
                    {"parametro": "urlResponse", "value": encryptData(`${dominio}${respuesta}`)},
                    {"parametro": "email", "value": encryptData(email)},
                    {"parametro": "commission", "value": encryptData(commission)},
                    {"parametro": "invoice", "value": encryptData(data.invoice)}
                ],
            "dominio": process.env.REACT_APP_DOMINIO_PAGAR
        },
    };

    let Obj = {};

    try {
        let token = await getToken(getCookie('dominioRecaudo'));

        await axios({
            data: bodies[body],
            url: process.env.REACT_APP_BASE_URL_REST_RECAUDO + "/api/recaudo/proyecto/api/consulta/facturas",
            method: "post",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${token}`,
                "Landing": "meter-plata",
                "X-api-key": tokenCaptcha
            }
        })
            .then(async result => {
                const {text_response} = result.data;
                if (result.status === 200 && result.data.success) {
                    const {facturas} = result.data.data;
                    setCookie("refPayco", facturas[0].identificacionEmpresa)
                    setCookie("transactionID", facturas[0].extra8)
                    setCookie("daviplata", daviplata)

                    if (window.__insp) {
                        window.__insp.push(['tagSession', {
                            ["refPayco: " + facturas[0].identificacionEmpresa]: facturas[0].identificacionEmpresa,
                            ["transactionID: " + facturas[0].extra8]: facturas[0].extra8,
                            ["daviplata: " + daviplata]: daviplata,
                            ["email: " + email]: email
                        }]);
                    }

                    Obj = {
                        status: true,
                        url: facturas[0].extra1,
                        message: text_response
                    }
                } else {
                    Obj = {
                        status: false,
                        message: text_response
                    }
                }
            })
            .catch(e => {
                Obj = {
                    status: false,
                    message: e.message
                }
            })

    } catch (e) {
        return e;
    }

    return Obj;
};

export const ProcesarRecargaDPago = async (data, type, tokenCaptcha = "") => {

    let dominio = window.location.origin;

    if (!data) {
        return ""
    }

    let {
        daviplata,
        value,
        bank,
        idRequest
    } = data;

    let respuesta = "/recargaD/respuesta";
    let commission = "1053";

    let body = {
        "consulta":
            [
                {"parametro": "daviplataCellphone", "value": encryptData(daviplata)},
                {"parametro": "value", "value": encryptData(value)},
                {"parametro": "bank", "value": encryptData(bank)},
                {"parametro": "urlResponse", "value": encryptData(`${dominio}${respuesta}`)},
                {"parametro": "idRequest", "value": encryptData(idRequest)},
                {"parametro": "commission", "value": encryptData(commission)},
                {"parametro": "email", "value": ''}
            ],
        "dominio": process.env.REACT_APP_DOMINIO_RECARGAR
    }


    let Obj = {};

    try {
        let token = await getToken(getCookie('dominioRecaudo'));
        await axios({
            data: body,
            url: process.env.REACT_APP_BASE_URL_REST_RECAUDO + "/api/recaudo/proyecto/api/consulta/facturas",
            method: "post",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${token}`,
                "Landing": "recarga-D",
                "X-api-key": tokenCaptcha

            }
        })
            .then(async result => {
                const {text_response} = result.data;
                if (result.status === 200 && result.data.success) {
                    const {facturas} = result.data.data;
                    setCookie("refPayco", facturas[0].identificacionEmpresa)
                    setCookie("transactionID", facturas[0].extra8)
                    setCookie("daviplata", daviplata)

                    if (window.__insp) {
                        window.__insp.push(['tagSession', {
                            ["refPayco: " + facturas[0].identificacionEmpresa]: facturas[0].identificacionEmpresa,
                            ["transactionID: " + facturas[0].extra8]: facturas[0].extra8,
                            ["daviplata: " + daviplata]: daviplata,
                            ["email: " + idRequest]: idRequest
                        }]);
                    }

                    Obj = {
                        status: true,
                        url: facturas[0].extra1,
                        message: text_response
                    }
                } else {
                    Obj = {
                        status: false,
                        message: text_response
                    }
                }
            })
            .catch(e => {
                Obj = {
                    status: false,
                    message: e.message
                }
            })

    } catch (e) {
        return e;
    }

    return Obj;
};


// OBTENER LOS BANCOS
export let GetTBanks = async (token = false) => {
    if(!token){
        token = await getToken(getCookie('dominioRecaudo'));
    }
    const url = `${process.env.REACT_APP_BASE_URL_REST_RECAUDO}/api/apify/payment/process/pse`;
    axios({
        url: url,
        method: "get",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${token}`
        }
    }).then(response => {
        const {data} = response;
        if (data.success) {
            const banks = data.data;
            store.dispatch({
                type: SET_STATE_GLOBAL,
                payload: {
                    banks,
                    loading: false
                },
            });
        } else {
            store.dispatch({
                type: SET_STATE_GLOBAL,
                payload: {
                    loading: false,
                    toast: {
                        status: true,
                        message: "Ocurrió un error bancos PSE."
                    }
                }
            });
        }
    })
        .catch(() => {
            store.dispatch({
                type: SET_STATE_GLOBAL,
                payload: {
                    loading: false,
                    toast: {
                        status: true,
                        message: "Ocurrió un error bancos PSE."
                    }
                }
            });
        });
};


// DISPARARA EL TOAST //
export const Setoast = (mensaje) => {
    return (
        store.dispatch({
            type: SET_STATE_GLOBAL,
            payload: {
                toast: {
                    status: true,
                    message: mensaje
                }
            }
        })
    )
};


// OBTENER LA COMISION
export const GetComision = async (number, amount) => {

    let Response = {};

    let token = await getToken(getCookie('dominioRecaudo'));

    await axios({
        data: {"commission": number},
        url: ` ${process.env.REACT_APP_BASE_URL_REST_RECAUDO}/api/apify/commission/list`,
        method: "post",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${token}`
        }
    })
        .then(result => {
            const {data} = result;
            setCookie("commission", 0);
            setCookie("canal", "");

            if (result.status === 200 && data.success) {
                let {
                    fixed,
                    percent,
                    limitTransaction,
                    channel
                } = data.data;

                setCookie("canal", channel);

                //  VALIDA EL MONTO QUE QUEIRE RECARGAR
                if (amount && amount > limitTransaction) {
                    Response.status = false;
                    Response.typeError = "monto";
                    return Response;
                }

                //  VALIDA SI VIENE UNA COMISION
                // if (fixed > 0 || percent > 0) {
                setCookie("commission", fixed ? fixed : percent);
                Response.status = false;
                store.dispatch({
                    type: SET_STATE_GLOBAL,
                    payload: {
                        comision: fixed ? fixed : percent,
                        modalComision: true
                    }
                });
                return Response;
                // }
                //  DEJALO PASAR SI NO VIENE COMISION
                // else {
                //     Response.status = true;
                //     Response = result;
                //     return Response;
                // }
            } else {
                Response.status = true;
                Response.typeError = "monto";
                Response.message = "No se pudo consultar la comision.";
                return Response;
            }
        })
        .catch((e) => {
            Setoast(`ERROR:${e}. Ocurrió un error, intente más tarde.`);
            Response.status = true;
            Response.message = "No se pudo consultar la comision.";
            return Response;
        });

    return Response;
};


// CONFIRM DEL MODAL
export const ProcesaRPModal = async (data, type, pagar, tokenCaptcha = "") => {

    store.dispatch({
        type: SET_STATE_GLOBAL,
        payload: {
            modalLoading: true
        }
    });
    let urlPSE = '';
    if (type === 'recargaD') {
        urlPSE = await ProcesarRecargaDPago(
            pagar ?
                pagar :
                {
                    daviplata: data.number.toString(),
                    value: data.amount.toString(),
                    bank: data.bank.toString(),
                    idRequest: data.idRequest,
                    email: ''
                }, type, tokenCaptcha);
    } else {
        urlPSE = await ProcesarRecargaPago(
            pagar ?
                pagar :
                {
                    daviplata: data.number.toString(),
                    value: data.amount.toString(),
                    bank: data.bank.toString(),
                    email: data.email.toString(),
                    idRequest: '',
                }, type, tokenCaptcha);
    }


    if (urlPSE.status) {
        window.location.assign(urlPSE.url);
    } else {
        Setoast(urlPSE.message);
        store.dispatch({
            type: SET_STATE_GLOBAL,
            payload: {
                modalLoading: false,
                modalComision: false
            }
        });
    }
}
