import React, {useEffect, useState} from "react";
import Btn from "./../UI/Btn";
import Flex from "./../UI/Flex";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {Container} from "./styles";
import {Itenvalue, TitleStatus} from "./helpers";
import moment from "moment";
import {PDFDownloadLink,pdf} from "@react-pdf/renderer";
import {PDF} from "../recarga/PDF";
import axios from "axios";
import {bool} from "yup";
import Fade from "./../UI/fade";
import Loading from "./../UI/loading";
import {generalPostMessage, numberFormatSeparator} from "../helpers";
import useGTM from '@elgorditosalsero/react-gtm-hook'
import {getCookie, getToken} from "../../helper";

const device = GFG_Fun();


function GFG_Fun() {
    let res = "IOS";
    const userAgent = navigator.userAgent.toLowerCase();
    const Android = userAgent.indexOf("android") > -1;

    if (Android) {
        res = "ANDROID";
    }

    return res;
}

function ResponseApp(props) {


    const [trx, SetT] = useState({
        loading: true,
        // loadingShare:true,
    });

    const [pdfUrl,setPdfUrl] = useState('');

    let share = async () => {

        if(trx.state === "Aceptada"){
            sendDataToGTM({
                eventCategory: 'Transaccion - Meter plata - Daviplata',
                eventAction: 'Click',
                eventLabel: 'Exitosa - Compartir',
                eventValue: '',
                event: 'eventClick'
            })
        }
        const blobPdf = await pdf(<PDF
            monto={"$ " + numberFormatSeparator(trx.amount.toString(), '.')}
            numero={"Daviplata " + trx.number}
            referencia={trx.refPayco}
            fecha={moment(trx.date).format("DD/MM/YYYY - HH:mm:ss")}
            costo={"$ " + trx.comision}
            transaccion={trx.state}
            cus={trx.cus}
            motivo={trx.description}
            nAprovacion={trx.trxId}
            direcionIp={trx.ip}
        />).toBlob();
        
        const pdfFile = new File([blobPdf], "DAV_Comprobante_AbonoPSE.pdf",{ type: "application/pdf" }); 
        generalPostMessage("sharePdf:" + pdfUrl, pdfUrl);
        
        try {
            await navigator.share({ files:[pdfFile] });
            SetT({...trx, textshare: 'MDN shared successfully'})
        } catch (err) {
            console.error(err);
            SetT({...trx, textshare: 'Error: ' + err})
        }
    };

    const publiKey = process.env.REACT_APP_PUBLIC_KEY;

    let ConsultaPSE = async () => {

        let transactionID = getCookie("transactionID");
        let token = await getToken(getCookie('dominioRecaudo'));

        let data = {
            "transactionID": transactionID
        };

        axios({
            data: data,
            url: ` ${process.env.REACT_APP_BASE_URL_REST_RECAUDO}/api/apify/payment/pse/transaction`,
            method: "post",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${token}`
            }
        }).then(response => {
            consult();
        }).catch(error => {
            consult();
        });
    };

    const handleDownload = () =>{
        generalPostMessage(`base64Download:${pdfUrl}`);
    }

    const consult = () => {

        let dataResponse = {
            status: bool(),
            data: {},
            message: "",
        };
        let refPayco = getCookie("refPayco");

        let url = process.env.REACT_APP_SECURE + "?public_key=" + publiKey + "&ref_payco=" + refPayco;

        axios.get(url)
            .then(responseTransaction => {
                if (responseTransaction.status === 200 && responseTransaction.data.success) {
                    let data = responseTransaction.data.data;
                    SetT({
                        ...trx,
                        amount: data.x_amount,
                        cus: data.x_approval_code,
                        bank: data.x_bank_name,
                        hola: data.x_cod_transaction_state,
                        currency: data.x_currency_code,
                        idClient: data.x_cust_id_cliente,
                        ip: data.x_customer_ip,
                        description: data.x_description,
                        date: data.x_fecha_transaccion,
                        franchise: data.x_franchise,
                        refPayco: data.x_ref_payco,
                        state: data.x_response,
                        trxId: data.x_transaction_id,
                        comision: getCookie("commission"),
                        number: getCookie("daviplata"),
                        loading: false,
                        fallida: data.x_response === "Fallida" || data.x_response === "Rechazada" || data.x_response === "Abandonada"
                    });
                } else {
                    dataResponse.status = true;
                    SetT({...trx, fallida: true, loading: false})
                }
            }).catch(error => {

            SetT({...trx, fallida: true, loading: false});
        });
    };


    useEffect(() => {
        ConsultaPSE();
        // Esto es para hacer una validacion para evitar alertas en los navegadores
        const Webkit = window.webkit;
        const Android = window.Android;

        if (device === 'ANDROID' && typeof Android !== "undefined") {
            window.Android.postMessage('lastPage')
        } else {
            if (device !== 'ANDROID' && window.innerWidth < 768 && typeof Webkit !== "undefined") {
                window.webkit.messageHandlers.getStoredData.postMessage('lastPage')
            }
        }
        window.addEventListener('message', function (e) {
            if (e.data === 'lastPage') {
                console.log(e.data, ":::::post message de last page:::::")
            }
        })
    }, []);
    const { sendDataToGTM } = useGTM();
    return (
        <Container direciont={"column"} className={"p-3 "}>
            {trx.loading ?
                <Loading only noBg/>
                :
                <Fade>
                    <div className="card ">

                        {(!trx.fallida && trx.state === "Aceptada") &&
                        <React.Fragment>
                            <TitleStatus text={"Transacción exitosa"} status={true}/>
                            <Itenvalue className={"bb"} name={"Número de celular"} value={trx.number}/>
                            <Itenvalue className={"bb"} name={"Valor de recarga"}
                                       value={"$ " + numberFormatSeparator(trx.amount.toString(), '.')}/>
                            <Itenvalue className={"bb"} name={"Fecha y hora de la transacción"}
                                       value={moment(trx.date).format("DD/MM/YYYY - HH:mm:ss")}/>
                            <Itenvalue name={"Costo de transacción"} value={"$ " + trx.comision}/>
                            <Flex className={"pb-4 pt-2 acciones"} alg={"stretch"}>

                                <div className={"row"}>
                                    <div className={"column"}>
                                        {trx.loadingShare ?
                                            <div className={"py-3 "}>
                                                <b>Cargando documento...</b>
                                            </div> :
                                            <button onClick={() => share()}>
                                                <Flex className={"wc"}>
                                                    <img src="/img/share.svg" alt=""/>
                                                    <span>Compartir</span>
                                                </Flex>
                                            </button>
                                        }
                                    </div>
                                    <div className={"separator"}>

                                    </div>
                                    <div className={"column"}>
                                        {trx.state !== "Pendiente" &&
                                        <div className={"wc col-6 px-0 position-relative"}>
                                            <PDFDownloadLink
                                                document={
                                                    <PDF
                                                        monto={"$ " + numberFormatSeparator(trx.amount.toString(), '.')}
                                                        numero={"Daviplata " + trx.number}
                                                        referencia={trx.refPayco}
                                                        fecha={moment(trx.date).format("DD/MM/YYYY - HH:mm:ss")}
                                                        costo={"$ " + trx.comision}
                                                        transaccion={trx.state}
                                                        cus={trx.cus}
                                                        motivo={trx.description}
                                                        nAprovacion={trx.trxId}
                                                        direcionIp={trx.ip}
                                                    />
                                                }
                                                fileName="DAV_Comprobante_AbonoPSE.pdf"
                                            >
                                                {({blob, url, loading, error}) => {
                                                    if(trx.state === "Aceptada"){
                                                        sendDataToGTM({
                                                            eventCategory: 'Transaccion - Meter plata - Daviplata',
                                                            eventAction: 'Click',
                                                            eventLabel: 'Exitosa - Descargar',
                                                            eventValue: '',
                                                            event: 'eventClick'
                                                        })
                                                    }
                                                    if (!loading && trx.loadingShare) {
                                                        SetT({...trx, loadingShare: false});
                                                    }

                                                    if(!loading && pdfUrl==''){
                                                        setPdfUrl(url);
                                                    }


                                                    return (loading ?
                                                        <div className={"py-3 "}>
                                                            <b>Cargando documento...</b>
                                                        </div>
                                                        :
                                                        <button
                                                            className={"py-2 text-left text-lg-left col-12 download px-0"}
                                                            onClick={()=>handleDownload(url)}>
                                                            <i className={"icon-download mr-1"}> </i>
                                                            Descargar
                                                        </button>)
                                                }}
                                            </PDFDownloadLink>
                                        </div>
                                        }
                                    </div>
                                </div>
                            </Flex>
                        </React.Fragment>
                        }
                        {trx.state === "Pendiente" &&
                        <React.Fragment>
                            <Flex className={"pendiente"} alg={"flex-start"}>
                                <Flex flex={"0 0 30px"} className={"cont-icon"}>
                                    <span><i className="icon icon-check-circle"> </i></span>
                                </Flex>
                                <Flex flex={"1 0 60%"} jc={"flex-start"}>
                                    <div className="tt">
                                        Transacción pendiente
                                    </div>
                                    <p className={"mb-2 fr"}>
                                        Estamos evaluando su solicitud. Le enviaremos un correo con la respuesta del
                                        pago aprobado o rechazado.
                                    </p>
                                </Flex>
                            </Flex>
                        </React.Fragment>
                        }

                        {trx.fallida &&
                        <React.Fragment>
                            <Flex className={"rechazada"} alg={"flex-start"} style={{padding: '0 5px'}}>
                                <Flex flex={"0 0 30px"} className={"cont-icon"}>
                                    <span><i className=" icon-cancel-circle"> </i></span>
                                </Flex>
                                <Flex flex={"1 0 60%"} jc={"flex-start"}>
                                    <div className="tt">
                                        Transacción rechazada
                                    </div>
                                    <p className={"mb-2 fr wc"}>
                                        Intente nuevamente
                                    </p>
                                </Flex>
                            </Flex>
                        </React.Fragment>
                        }

                        <Flex className={"action"}>
                            <Btn
                                size={"md"}
                                bRadius={"22px"}
                                mw={"136px"}
                                onClick={() => {
                                    if (device === 'ANDROID') {
                                        window.Android.postMessage('finalizar');
                                    } else {
                                        if (window.innerWidth < 768) {
                                            window.webkit.messageHandlers.getStoredData.postMessage('finalizar')
                                        }
                                    }
                                    console.log('di clic finalizar response app')
                                    console.log(trx)
                                    getCookie("confirm","");
                                    if(trx.state === "Aceptada"){
                                        sendDataToGTM({
                                            eventCategory: 'Transaccion - Meter plata - Daviplata',
                                            eventAction: 'Click',
                                            eventLabel: 'Exitosa - Finalizar',
                                            eventValue: '',
                                            event: 'eventClick'
                                        })
                                    } else if(!trx.state === "Pendiente") {
                                        sendDataToGTM({
                                            eventCategory: 'Transaccion - Meter plata - Daviplata',
                                            eventAction: 'Click',
                                            eventLabel: 'Rechazada - Finalizar',
                                            eventValue: '',
                                            event: 'eventClick'
                                        })
                                    }
                                }}
                            >Finalizar
                            </Btn>
                        </Flex>
                    </div>
                </Fade>
            }
        </Container>
    )
}

const MapstateToProps = state => ({
    response: state.global.response,
    global: state.global
});

export default connect(MapstateToProps, null)(withRouter(React.memo(ResponseApp)));
