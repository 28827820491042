import React, {useState, useEffect} from "react";
import {Formik} from "formik";
import * as Yup from "yup";
import Field from "./../UI/field";
import Flex from "./../UI/Flex"
import Btn from "./../UI/Btn"
import ShowModal from "./../UI/modal/ShowModal";
import {connect} from "react-redux";
import {Container} from "./styles";
import {hanlderValue, numberFormatSeparator, messageValidForm, bankLockRechargeD} from "../helpers";
import {setGlobalData} from "../../store/actions/actionsCreators";
import {GetComision, ProcesaRPModal, Setoast, ProcesarRecargaDPago, setCookie} from "../../helper";
import Loading from "../UI/loading";
import Fade from "./../UI/fade";
import Select from "../UI/select";
import useGTM from '@elgorditosalsero/react-gtm-hook'
import CaptchaComponent from '../captcha'

function RecargaDaviplataV(props) {

    const [bankLock, setBanks] = useState(bankLockRechargeD);
    const [processing, setProcessing] = useState(false);
    const [errorServer, setE] = useState({
        number: "",
        amount: ""
    });

    const [data, SetData] = useState({
        number: "",
        confirmNumber: "",
        amount: "",
        bank: ""

    });

    const RecargaSchema = Yup.object({
        number: Yup.string()
            .min(10, messageValidForm.phone)
            .required('error'),
        confirmNumber: Yup.string()
            .min(10, messageValidForm.phone)
            .oneOf([Yup.ref('number'), null], messageValidForm.confirm)
            .required('error'),
        amount: Yup.number()
            .min(10000, messageValidForm.min)
            .max(5000000, messageValidForm.max)
            .required('error'),
        bank: Yup.string()
            .min(4, messageValidForm.bank)
            .required('error')
    });
    const handlePaste = (e) => {
        e.preventDefault()
    }
    const onChageField=(element, setFiel= ()=>{})=>{
        const { name, value } = element.target;
        let valueEnd = value;
        if(name == 'number' || name == 'confirmNumber' || name == 'amount' || name == 'bank'){
            valueEnd = parseInt(valueEnd) || '';
        }

        setFiel([name], valueEnd);
        SetData({...data, [name]: valueEnd});
    };

    useEffect(() => {
        setCookie("confirm", "false");
        let search = window.location.search;
        let recharge = new URLSearchParams(search).get("recharge");
        if (!recharge || recharge == '') {
            window.location.replace('/meter-plata')
        }
    }, []);
    const { sendDataToGTM } = useGTM();
    return (
        <Container direction={"column"}>
            {props.global.loading ?
                <Loading only noBg/>
                :
                <Fade>
                    <Flex className="content" >
                        <div className="card " style={{ margin: "0 12px"}}>
                            <div className="title">Complete los datos para meter plata a DaviPlata</div>
                            <Formik
                                initialValues={{
                                    number: "",
                                    confirmNumber: "",
                                    amount: "",
                                    bank: ""
                                }}
                                validationSchema={RecargaSchema}
                                onSubmit={async (dataValues) => {
                                    sendDataToGTM({
                                        eventCategory: 'Proceso de pago - Daviplata',
                                        eventAction: 'Click',
                                        eventLabel: 'Información del Pago - Continuar',
                                        eventValue: '',
                                        event: 'eventClick'
                                    })
                                    let search = window.location.search;
                                    let recharge = new URLSearchParams(search).get("recharge");

                                    data.idRequest = recharge;

                                    setProcessing(true);

                                    //OBTEN LA COMISIÓN
                                    let Comision = await GetComision("1053", dataValues.amount);
                                    if (Comision.status) {
                                        // LLÉVALO A PSE SI TODO BIEN

                                        let urlPSE = await ProcesarRecargaDPago({
                                            daviplata: data.number.toString(),
                                            value: data.amount.toString(),
                                            bank: data.bank.toString(),
                                            idRequest: recharge
                                        }, "recargaD", props.tokenCaptcha);

                                        if (urlPSE.status) {
                                            window.location.assign(urlPSE.url);
                                        } else {
                                            setProcessing(true);
                                            Setoast(urlPSE.message);
                                        }
                                    } else {
                                        setProcessing(false);

                                        //SI EL ERROR ES POR EL MONTO
                                        if (Comision.typeError === "monto") {
                                            Setoast("DaviPlata excede tope.");
                                            setE({...errorServer, amount: "Monto no permitido."})
                                        }
                                    }
                                }}
                            >
                                {({errors, values, touched, setFieldValue, handleSubmit}) => (
                                    <form className={"wc"}
                                          onSubmit={e => {

                                              if (Object.keys(errors).length > 0) {
                                                  let message = errors.number ||
                                                      errors.confirmNumber ||
                                                      errors.amount ||
                                                      errors.bank ||
                                                      "Los datos ingresados son incorrectos";
                                                  Setoast(message);
                                              }
                                              e.preventDefault();
                                              handleSubmit();
                                          }}
                                    >
                                        <Field
                                            line
                                            font={"Roboto"}
                                            label="¿A qué número quiere meter plata?"
                                            placeholder={"Número celular"}
                                            value={values.number}
                                            onChange={e => {
                                                onChageField({
                                                    target:{
                                                        name: 'number',
                                                        value: hanlderValue(e.target.value, 10)
                                                    }
                                                }, setFieldValue);
                                            }}
                                            error={(errors.number && touched.number) || errorServer.number}
                                            labelSize={"16px"}
                                            className={"mb-4"}
                                            type={"tel"}
                                            inputHeight={"22px"}
                                            marginTop={"2%"}
                                            disabled={processing}
                                        />

                                        <Field
                                            line
                                            font={"Roboto"}
                                            label="Confirme el número DaviPlata"
                                            placeholder={"Confirme número DaviPlata"}
                                            value={values.confirmNumber}
                                            onPaste={e => {
                                                handlePaste(e)
                                            }}
                                            onChange={e => {
                                                onChageField({
                                                    target:{
                                                        name: 'confirmNumber',
                                                        value: hanlderValue(e.target.value, 10)
                                                    }
                                                }, setFieldValue);
                                            }}
                                            error={errors.confirmNumber && touched.confirmNumber}
                                            labelSize={"16px"}
                                            className={"mb-4"}
                                            type={"tel"}
                                            inputHeight={"22px"}
                                            marginTop={"2%"}
                                            disabled={processing}
                                        />

                                        <Field
                                            line
                                            font={"Roboto"}
                                            label="¿Cuánta plata quiere meter?"
                                            placeholder={"Valor"}
                                            value={numberFormatSeparator(values.amount, '.')}
                                            onChange={e => {
                                                onChageField({
                                                    target:{
                                                        name: 'amount',
                                                        value: hanlderValue(e.target.value, 7)
                                                    }
                                                }, setFieldValue);
                                            }}
                                            error={errors.amount && touched.amount || errorServer.amount}
                                            labelSize={"16px"}
                                            className={"mb-4"}
                                            before
                                            type={"tel"}
                                            inputHeight={"22px"}
                                            marginTop={"2%"}
                                            disabled={processing}
                                            // ClveOptions={{ numeral: true, numeralDecimalMark: '.', delimiter: ',', rawValueTrimPrefix: true, blocks: [3, 3] }}
                                            // Clve
                                        />

                                        <Field
                                            type={"tel"}
                                            line
                                            font={"Roboto"}
                                            label="¿De qué banco quiere meter plata?"
                                            error={errors.bank && touched.bank}
                                            labelSize={"16px"}
                                            className={"mb-0"}
                                            inputHeight={"22px"}
                                            marginTop={"2%"}
                                            disabled={processing}
                                            color={"#6c757d"}
                                        >
                                            <Select
                                                value={values.bank}
                                                resource={props.global.banks}
                                                valueLock={bankLock}
                                                renderValue={'bankCode'}
                                                placeholder={'Seleccione el banco...'}
                                                renderName={'bankName'}
                                                onChange={e => {
                                                    onChageField({
                                                        target:{ name: 'bank', value: e.target.value }
                                                    }, setFieldValue);
                                                    props.global.banks.map( element =>{
                                                        if(element.bankCode=== e.target.value){
                                                            sendDataToGTM({
                                                                eventCategory: 'Transaccion - Meter plata - Daviplata',
                                                                eventAction: 'Click',
                                                                eventLabel: element.bankName,
                                                                eventValue: '',
                                                                event: 'eventClick'
                                                            })
                                                        }

                                                    })
                                                }}
                                                disabled={processing}
                                            />
                                        </Field>
                                        <CaptchaComponent className={"mb-0"}/>

                                        <Flex className={"action"}>
                                            <Btn
                                                bRadius={"22px"}
                                                mw={"160px"}
                                                size={"md"}
                                                disabled={!(
                                                    data.bank !== "" &&
                                                    parseInt(data.amount) > 0 &&
                                                    parseInt(data.number) > 0 &&
                                                    parseInt(data.confirmNumber) > 0
                                                ) || props.tokenCaptcha === ""}
                                                className={"mr-2"}
                                                type={'submit'}
                                                loading={processing}
                                            >
                                                Continuar
                                            </Btn>
                                        </Flex>
                                    </form>
                                )}
                            </Formik>
                        </div>


                        <ShowModal
                            show={props.global.modalComision}
                            onClose={() => props.setGlobalData({modalComision: false})}
                            onConfirm={async () => {
                               sendDataToGTM({
                                    eventCategory: 'Proceso de pago - Daviplata',
                                    eventAction: 'Click',
                                    eventLabel: 'Advertencia tope PSE - Aceptar',
                                    eventValue: '',
                                    event: 'eventClick'
                                })
                                await ProcesaRPModal(data, "recargaD", false, props.tokenCaptcha);
                            }}
                            MW={"400px"}
                            hideClose
                            loading={props.global.modalLoading}
                        >
                            <div className={"wc py-4 mb-3 px-3"}>
                                <p className={"fr w300 text-center d-block mb-0"}>
                                    Esta transacción tiene un costo
                                    de <b>${numberFormatSeparator(props.global.comision, '.')} </b> <br/>¿Desea continuar?
                                </p>
                            </div>
                        </ShowModal>
                    </Flex>
                </Fade>
            }
        </Container>
    )
}

const mapStateToProps = state => ({
    tokenCaptcha: state.global.tokenCaptcha,
    global: state.global
});
const MDTprops = {setGlobalData};

export default connect(mapStateToProps, MDTprops)(React.memo(RecargaDaviplataV));
