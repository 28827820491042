import ReactGA from 'react-ga';

export const hanlderValue = function (value, max) {
    var e;
    e = value.replace(/[^0-9]/g, "");
    if (max) {
        e = e.substring(0, max)
    }
    return e;
};

export const isMobile = function (platform) {
    const Android = navigator.userAgent.match(/Android/i);
    const Webos = navigator.userAgent.match(/webOS/i);
    const Iphone = navigator.userAgent.match(/iPhone/i);
    const Ipad = navigator.userAgent.match(/iPad/i);
    const Ipod = navigator.userAgent.match(/iPod/i);
    const Blackberry = navigator.userAgent.match(/BlackBerry/i);
    const Windowsphone = navigator.userAgent.match(/Windows Phone/i);

    const platforms = {Android, Webos, Iphone, Ipad, Ipod, Blackberry, Windowsphone};

    if (typeof platform !== "undefined" && typeof platform !== undefined && typeof platform == "string" && platform !== "") {
        return platforms[platform];
    }

    return (Android || Webos || Iphone || Ipad || Ipod || Blackberry || Windowsphone);

}

export function isValid(data, type) {
    return (
        typeof data !== "undefined" &&
        typeof data !== undefined &&
        typeof data !== "" &&
        ((typeof type !== undefined && typeof type == 'string') ? (typeof data == type) : true)
    )
}

export function numberFormatSeparator(x, separator = ",") {
    const _isString = isValid(separator);
    const _separator = _isString ? separator.substring(0, 1) : ",";
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, _separator);
}

export const validEmail = (value) => {
    return isValid(value, 'string') ? value.replace(/[^a-zA-Z0-9@.\-_~!$&'()*+,;=:]/g, "") : '';
}

export function Device() {
    var res = "IOS";
    var userAgent = navigator.userAgent.toLowerCase();
    var Android = userAgent.indexOf("android") > -1;
    if (Android) {
        res = "ANDROID";
    }
    return res;
}

export function BankBlock(value, compared) {
    const valueLC = isValid(value) ? value.toLowerCase() : 'undefined';
    const comparedLC = isValid(compared) ? compared.toLowerCase() : 'null';
    return valueLC.indexOf(comparedLC, 0) > 0;
}

export function addGAEvent(category,action){
    ReactGA.event({
        category: category,
        action: action
    });
}

const isProduction = (process && process.env.NODE_ENV == 'production');
/**
 * 1051 : Daviviendia
 * 1551 : DaviPalta
 * 1151 : RapiPay
 */
export const bankLockRecharge = isProduction ? [
    1151, 1551, 0
] : [];

export const bankLockRechargeD = isProduction ? [
    1151, 1551, 0
] : [];

export const bankLockPayment = isProduction ? [
    1551, 0
] : [];
/**
 * Post Message
 * @param name
 * @param value
 */
export const generalPostMessage = (name, value) => {

    // Esto es para hacer una validacion para evitar alertas en los navegadores
    const isAndroid = isMobile("Android");

    if (isAndroid) {
        if(window.Android){
            window.Android.postMessage(name);
        }else if(window.android){
            window.android.postMessage(name);
        }else{
            window.postMessage(name);    
        }
    } else if(window.webkit) {
        window.webkit.messageHandlers.getStoredData.postMessage(name)
    }
};

export const messageValidForm = {
    email: 'El correo ingresado es inválido.',
    receptor: 'El Receptor es inválido.',
    daviplata: 'El DaviPlata destino no existe o no se encuentra activo.',
    phone: 'Número de celúlar inválido',
    confirm: 'Los datos del número de celular no coinciden.',
    bank: 'El banco destino no existe o no se encuentra activo.',
    min: 'El valor ingresado es menor al monto mínimo permitido.',
    max: 'El valor ingresado supera el monto máximo permitido.',
    required: ''
};
