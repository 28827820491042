import styled from "@react-pdf/styled-components";
import {Document, Page, Image, View, StyleSheet, Text, Font} from "@react-pdf/renderer";
import React from "react";
import RobotoCondensedBold from '../../fonts/RobotoCondensed-Bold.ttf';
import RobotoCondensedRegular from '../../fonts/RobotoCondensed-Regular.ttf';

const Ccontainer = styled.View`
    padding:0;
    width:100%;
    margin:auto;
    height:100%;
    font-family: 'Helvetica';
    justify-content:flex-start;
    position: relative;
`;

const Card = styled.View`
    position: relative;
    padding:20pt 29pt;
    border-radius: 6pt;
    width: 100%;
    max-width: 80%;
    margin: 0 auto;
    border: 1pt solid #F2F2F2;
    background-color: #F1F1F1;
    color: #5C5B5C;
    .title{
      color: #343A40 !important;
    }
    
`;


const RTitle = styled.Text`
    font-size: ${props => props.size ? props.size : '22pt'} ;
    font-weight: ${props => props.fontWeight ? props.fontWeight : '400'};
    text-align: ${props => props.align ? props.align : 'left'} ;
    padding-bottom: ${props => props.marginBottom ? props.marginBottom : '5pt'};
    padding-top: ${props => props.marginTop ? props.marginTop : '5pt'};
`;

const Rname = styled.Text`
    width: 50%;
    left: 0;
    position: absolute;
    font-size:12pt;
    font-weight:bold;
    text-align: left;
    padding-top: 5pt;
    padding-left: 2.5pt;
    padding-bottom: 2.5pt;
    // background-color: red;
`;

const RValue = styled.Text`
    width: 50%;
    right: 0;
    position: absolute;
    font-size:11pt;
    font-weight:300;
    text-align: right;
    padding-top: 5pt;
    padding-left: 2.5pt;
    padding-bottom: 2.5pt;
    color: #777777;
    // background-color: orange;
`;

const Rborder = styled.View`
    width: 100%;
    display: flex;
    position: relative;
    padding-top: 10pt;
    padding-bottom: 10pt;
    border-bottom: 1pt solid ${props => props.color}; 
    // background-color: fuchsia;
`;
const ViewImages = styled.View`
  width: 30%;
  height: 66pt;
  padding: 15pt;
  display: block;
  margin: 0 auto;
  position: relative;
  align-items: center;
  justify-content: center;
  // background-color: #DD0014;
`;

const ItemPdf = ({name, value, noB}) => {
    return (
        <Rborder color={noB ? "#F1F1F1" : "#D3D3D3"}>
            <Rname>{name}</Rname>
            <RValue>{value}</RValue>
        </Rborder>
    )
};

const styles = StyleSheet.create({
    page: {marginLeft: 0, marginRight: '0 !important'},
    section: {
        '@page': {
            width: 300,
            margin: 0
        }
    },
    logo: {
        width: "165px",
        height: "74px",
    },
    Fheader: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#373737',
        padding: '10px 20px',
        height: '80px',
        textAlign: 'center'
    },
    Ffooter: {
        width: '100%',
        height: '160px',
        paddingTop: '20pt',
        position: 'absolute',
        backgroundColor: '#373737',
        color: '#FFF',
        textAlign: 'center',


        right: 0,
        left: 0,
        bottom: 0,
    },
    Ficon: {
        left: '5px',
        width: '28px',
        height: '28px',
        margin: '5px 10px',
        position: 'absolute',
        borderRadius: '100pt',
        backgroundColor: '#FFF',
    },
    iconSocial: {
        width: '28px',
        height: '28px',
    },
    FFtitle: {
        fontSize: '14pt',
        textAlign: 'center',
        marginTop: '10pt',
        marginBottom: '20pt',
    },
    Ftitle: {
        fontFamily: "rBold",
        fontSize: '28pt',
        textAlign: 'center',
        marginTop: '27pt',
        marginBottom: '20pt',
    },
    Fbold: {
        fontFamily: "rBold",
    },
    Fregular: {
        fontFamily: "rRegular",
    },

});

Font.register({
    family: 'rBold',
    src: RobotoCondensedBold,
});

Font.register({
    family: 'rRegular',
    src: RobotoCondensedRegular,
});

export const Pdf = ({referencia, monto, transaccion, fecha, direcionIp, cus, cliente, bank, numero, motivo, costo}) =>
    <Document>
        <Page size="A4" style={styles.page}>
            <Ccontainer>

                <View style={styles.Fheader}>
                    {/*<Image src={`${process.env.REACT_APP_BASE_MULTIMEDIA}/dashboard/logo-epayco-blanco.png`} style={styles.logo}/>*/}
                </View>
                <Text style={styles.Ftitle}>Resumen de su compra</Text>
                <RTitle style={styles.Fbold} align={'center'} marginBottom={'2pt'}>Comercio: {cliente}</RTitle>
                <RTitle style={styles.Fregular} align={'center'} marginBottom={'30pt'} marginTop={'1pt'} size={'12pt'}>Fecha
                    transacción: {fecha}</RTitle>


                <Card>
                    <RTitle className={'title'} style={styles.Fbold} marginBottom={'8pt'} size={'16pt'}>Resultado de la
                        transacción</RTitle>
                    <ItemPdf name={"Estado: "} value={transaccion}/>
                    <ItemPdf name={"Origen de pago: "} value={bank}/>
                    <ItemPdf name={"Destino de pago: "} value={"******" + numero.substring(6, 10)}/>
                    <ItemPdf name={"Autorización / CUS: "} value={cus}/>
                    <ItemPdf name={"Motivo: "} value={motivo}/>
                    <ItemPdf name={"No. de aprobación: "} value={referencia}/>
                    <ItemPdf name={"Dirección IP: "} value={direcionIp} noB/>

                    <RTitle className={'title'} style={styles.Fbold} marginBottom={'8pt'} marginTop={'5pt'}
                            size={'16pt'}>Valor transacción</RTitle>
                    <ItemPdf name={"Total: "} value={monto}/>
                    <ItemPdf name={"Costo transacción: "} value={`${costo} Iva incluido`}/>
                </Card>


                <View style={styles.Ffooter}>
                    <ViewImages>
                        <Image src={`${process.env.REACT_APP_BASE_MULTIMEDIA}/dashboard/images/fb.png`}
                               style={styles.Ficon}/>

                        <Image src={`${process.env.REACT_APP_BASE_MULTIMEDIA}/dashboard/images/ig.png`}
                               style={{...styles.Ficon, left: '45px'}}/>

                        <Image src={`${process.env.REACT_APP_BASE_MULTIMEDIA}/dashboard/images/tw.png`}
                               style={{...styles.Ficon, left: '85px'}}/>

                        <Image src={`${process.env.REACT_APP_BASE_MULTIMEDIA}/dashboard/images/in.png`}
                               style={{...styles.Ficon, left: '125px'}}/>
                    </ViewImages>
                    {/*<Text style={styles.FFtitle}>
                            ePayco, la solución para el procesamiento de sus pagos. ¡Conozca más!
                        </Text>
                    */}
                </View>
            </Ccontainer>
        </Page>
    </Document>
;
