import React, {useEffect, useState} from "react";
import {CSSTransition} from "react-transition-group";


function Fade({children}) {

    let [show,setShow] = useState(false);

    useEffect(()=>{
        setShow(true)
    },[]);

    return (
             <CSSTransition
                 in={show}
                 timeout={600}
                 classNames="fadeUp"
                 unmountOnExit
             >
                {children}
             </CSSTransition>
    )
}

export default React.memo(Fade);
