import {
    SET_STATE_GLOBAL
} from "../actions";

const initialState = {
    toast: {
        status: false,
        message: ""
    },
    tokenCaptcha: "",
    response: {
        numero: "3506928681",
        amount: "150000",
        fecha: "22/12/20 - 15:00",
        cost: "$0",
        status: "aceptada"
    },
    banks: [],
    loading:true,

    // Modal de la comision
    comision:0,
    modalComision:false,
    modalLoading : false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_STATE_GLOBAL:
            return {...state, ...action.payload};
        default:
            return state
    }
}
