import React from "react";
import styled from "@emotion/styled";


const Container = styled.div`
    position:relative;
    font-weight:bold;
    font-style:italic;
    font-size:50px;      
    color:${props => props.color};
    margin:${props => props.margin};
    
    @media all and (max-width:540px){
        font-size:30px;
    }
    
    @media all and (max-width:340px){
        font-size:24px;
    }
`;

function Title({children,color,className, margin}) {
    return (
        <Container color={color} className={className} margin={margin ? margin : "30px 0"}>
           {children}
        </Container>
    )
}

export default React.memo(Title);
