import React from "react";
import styled from "@emotion/styled";
import Flex from "../UI/Flex";
import {colors} from "../UI";

const Container = styled.div`
    position:relative;
    padding:34px 40px;
    width:100%;
    max-width:500px;
    
    .icon{
        font-size:35px;
        line-height:35px;
        color:${colors.rojo01};
    }
    
    .texto01{
        font-weight:700;
        font-size:30px;
        max-width:311px;
        line-height:1.1em;
        
        @media all and (max-width:480px){
            font-size:18px;
            max-width:185px;
        } 
    }
    
    .texto02{
      font-weight:400;
      font-size:25px;
      padding: 20px 0;
      line-height:1em;
      
      .strng{
          display:inline-block;
          font-weight:700;
          margin-left:7px;
      }
      
      @media all and (max-width:480px){
          font-size:16px;
      } 
    }
    
    @media all and (max-width:480px){
            padding: 20px 30px;
    } 
     
  
`;

function Modal(
    {
        type,
        comision,
        textLeft,
        textRight,
        message= "El DaviPlata ingresado es inválido",
        className
    }) {
    const text_align = (typeof textLeft !== "undefined" && typeof textLeft !== undefined) ? 'text-left'
        : (typeof textRight !== "undefined" && typeof textRight !== undefined)
            ? 'text-right' : 'text-center'
    return (
        <Container className={className}>
            {type === "error" ?
            <Flex className={"wc"} alg={"flex-start"} jc={"flex-start"}>
                <Flex flex={"0 0 40px"} className={"icon"}>
                    <i className={"icon-info"}> </i>
                </Flex>
                <Flex className={`texto01 pl-3 ${text_align ||''}`} flex={"1 0 70%"}>
                    {message}
                </Flex>
            </Flex>
            :
            <Flex className={"wc"} alg={"flex-start"} jc={"flex-start"}>
                <Flex className={`texto02  ${text_align ||''}`} flex={"1 0 60%"}>
                  Esta transacción tiene un costo de <span className={"strng"}>${comision} </span>
                  <br />¿Desea continuar?
                </Flex>
            </Flex>
            }
        </Container>
    )
}

export default React.memo(Modal);
