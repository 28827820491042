import React from "react";
import styled from "@emotion/styled";
import {colors} from "../index";
import Flex from "./../Flex";
import Cleave from 'cleave.js/react';

const Container = styled.div`
    position:relative;
    flex:${props => props.flex};
    max-width:500px;
    margin-bottom:14px;
    .prefix{
        input{
          padding-left: 20px;
        }
        &::after {
          content: ${props => props.prefix ? `"${props.prefix}"`: null}; 
          display: flex;
          position: absolute;
          width: 20px;
          height: 100%;
          background-color: transparent;
          left: 0;
          z-index: 1;
          align-items: center;
          padding-left: 5px;
          margin: auto;
          font-size: 1.5em;
          color:  ${props => props.theme.color};
        }
    }
    
    label{
        font-size:${props => props.theme.label.size};
        color:${props => props.theme.label.color};
        font-weight:${props => props.theme.label.weight};
        text-align:left;
        width:100%;
        font-family:${props => props.theme.label.font};
        margin-bottom:${props => props.theme.label.margin};
        
        
        @media all and (max-width:340px){
            font-size: ${props => props.theme.label.size === "16px" ? "14px" : props.theme.label.size}; 
        }
        
        @media all and (max-width:768px){
            font-size: 16px; 
        }
     }
    
    input,select,textarea{
        flex:10 0 70%;
        background-color:${props => props.theme.bg};
        color:  ${props => props.theme.color};
        cursor: ${props => props.theme.cursor};
        border-radius:${props => props.theme.borderRadius}; 
        height:${props => props.theme.input.height};
        margin-top: ${props => props.theme.input.marginTop};
        width:100%;
        padding:${props => props.theme.input.padding};
        border:none;
        border-bottom: ${props => props.theme.input.border};
        font-weight:${props => props.theme.input.weight};
        font-family:${props => props.theme.input.font};
        font-size:${props => props.theme.input.fZise};
        &:disabled{
          border-bottom: ${props => props.theme.input.border};
        }
        
        &:focus{
            outline:none;
            border-bottom:${props => props.theme.focus.border};   
        }
        
        @media all and (max-width:768px){
            font-size: 16px;
        }  
    }
    
    .bfr{
        font-size:18px;
        position:absolute;
        left:0;
        bottom:0;
        width:20px;
        height:100%;
        color:${props => props.theme.focus.color};
        
        @media all and (min-width:768px){
            font-size:${props => props.theme.input.fZise};
        }  
    }
    
    .pesos{
        font-size:${props => props.theme.input.fZise};
        color: ${colors.errorlabel} !important;
        margin-left: -3%;
        
        @media all and (max-width:768px){
            font-size: 16px;
        } 
    }
    
    textarea{
        height:70px;
    }
`;

function Field(
    {
        flex = "1 0 100%",
        children,
        disabled,
        error,
        label,
        type = "text",
        onChange,
        value,
        name,
        placeholder,
        prefix,
        style,
        mw,
        className,
        line,
        before,
        labelSize,
        inputHeight,
        font,
        weight,
        ClveOptions,
        Clve,
        fZise,
        marginTop,
        color,
        onPaste= null

    }) {

    const theme = {
        bg: error ? (line ? "transparent" : colors.errorbg) : (line ? "transparent" : colors.white),
        color: error ? colors.rojo01 : disabled ? '#000000' : color ? color : '#000000',
        cursor: disabled ? "not-allowed" : "pointer",
        borderRadius: line ? "0" : "7px",
        label: {
            margin: line ? "3px" : "5px",
            color: error ? colors.errorlabel : colors.black01,
            weight: line ? (weight ? weight : "regular") : "bold",
            size: line ? (labelSize ? labelSize : "20px") : "24px",
            focus: {
                bColor: colors
            },
            font: font ? font : 'Roboto Condensed',
        },
        input: {
            borderR: line ? "none" : "7px",
            border: line ? (`0.1px solid ${error ? colors.rojo01 : colors.grey01}`) : "none",
            bg: line ? "transparent" : (error ? colors.errorbg : colors.white),
            height: line ? (inputHeight ? inputHeight : "35px") : "42px",
            marginTop: marginTop ? marginTop : "none",
            // padding : line ?  (before ? "0 0 0.2em 30px" : "0 0 0.2em") : (before ? "0 0 0.2em 30px" :" 0 .75em"),
            color: error ? colors.rojo01 : "black",
            weight: line ? "300" : "400",
            font: font ? font : "Roboto Condensed",
            fZise: fZise ? fZise : line ? "22" : "16px"
        },
        border: line ? "1px solid " + colors.grey03 : "none",
        focus: {
            border: line ? "1px solid " : "none",
        }
    };
    const prefixText = (type == "text" || type == "number");
    return (
        <Container
            className={className}
            prefix={ prefixText ? prefix : null}
            flex={flex}
            theme={theme}
            style={style}
            mw={mw}
        >
            <label>{label} {error && <i className="icon icon-info-circle"> </i>}</label>
            {children ?
                children :
                <Flex
                    className={`wc ${(typeof prefix !== "undefined" && prefixText) ? 'prefix' : ''}`}
                >
                    {before &&
                    <Flex flex={"0 0 35px"} className={"pesos"}>
                        $
                    </Flex>
                    }
                    {
                        Clve ?
                            <Cleave
                                options={ClveOptions}
                                type={type}
                                onChange={onChange}
                                value={value}
                                name={name}
                                placeholder={placeholder}
                                disabled={disabled}
                                onPaste={onPaste}
                            /> :
                            <input
                                type={type}
                                onChange={onChange}
                                value={value}
                                name={name}
                                placeholder={placeholder}
                                disabled={disabled}
                                onPaste={onPaste}
                            />
                    }

                </Flex>
            }
        </Container>
    )
}

export default React.memo(Field);
