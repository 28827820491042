import {
    SET_PAGOS_DATA,
    SET_RACARGAS_DATA,
    SET_STATE_GLOBAL
} from "./index";

export const setDataPagos = (value)=> ({
    payload:value,
    type:SET_PAGOS_DATA
});

export const setDataRecargas = (value)=> ({
    payload:value,
    type:SET_RACARGAS_DATA
});

export const setGlobalData = (value)=> ({
    payload:value,
    type:SET_STATE_GLOBAL
});
