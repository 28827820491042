import React from "react";
import styled from "@emotion/styled";
import {colors} from "./UI";
import {withRouter} from "react-router-dom"


const Container = styled.div`
    position:relative;
    background:${props => props.bg};
    height:auto;
    width:100%;
    padding:7px 25px;
    
    img{
        height: 56px;
        width:auto;
    }
    
    @media all and (max-width:540px){
        text-align:center;
    }
`;

function Header(props) {

    const path =  props.history.location.pathname;

    let types = {
        original:'https://multimedia.epayco.co/Brand/daviplata/daviplata.png',
        blanco:"https://multimedia.epayco.co/Brand/daviplata/daviplata-blanco.png"
    };

    let bg   = props.type === "rojo" ?  colors.rojo01   : "transparent" ;
    let logo = props.type === "rojo" ?  types['blanco'] : types['original'];

    if( path === "/recargaD" || path === "/responseD" || path === "/pagar" || path === "/pagar/respuesta") {
        return " "
    }

    return (
        <Container bg={bg} className={props.className}>
            <div className="col-12 col-xl-11 mx-auto text-md-left text-center">
                <img src={logo} alt=""/>
            </div>
        </Container>
    )
}

export default React.memo(withRouter(Header));
