import React, {Component} from "react";
import ReactDOM from "react-dom";
import {CSSTransition} from 'react-transition-group';
import Flex from "../Flex";
import Btn from "../Btn";
import styled from "@emotion/styled";

const MaskClose = styled.div`
    display: flex;
    height: 100%;
    flex: 1 1 1%;
    @media only screen and (max-width: 570px){
      display: none;
      visibility: hidden;
    }
`;
const Modal = props => {

    let small = window.innerWidth < 768;
    const dark = props.masOscuro ? "masOscuro" : '';
    const class_Name = props.className ? props.className : '';

    return (

        <CSSTransition
            in={!!props.show}
            timeout={600}
            classNames="fade"
            unmountOnExit
        >
            <Flex className={"modal-container"} direction={"column"}>

                <div className={`sombra-modal ${dark}`} onClick={
                    (props.closeMask && props.type == 'error') ? props.onClose : ()=>{}
                }> </div>

                <Flex className="area wc position-relative px-2">
                    {(props.closeMask && props.type == 'error')
                        ? <MaskClose onClick={ props.closeMask ? props.onClose : () => {}}/> : null}
                    {!props.reset ?
                        <div className={`card ${class_Name} wc`} style={{maxWidth:props.MW  ? props.MW : "502px" }}>
                            {!props.hideClose &&
                                <div className="close close2"
                                     onClick={props.onClose}
                                >
                                    <i className={"icon-cancel"}> </i>
                                    {/*<img src="/img/iconos/close.svg" alt="" className={"imgr"}/>*/}
                                </div>
                            }
                            <div className="content">
                                {props.children}
                            </div>

                            {!props.noBtn &&
                                <Flex className={"wc botones px-0"} jc={"space-around"} flex={"0 0 350px"}>
                                    <Btn
                                        size={small ? "md" : "lg"}
                                        bRadius={"22px"}
                                        mw={small ? "114px" :"150px"}
                                        className={"mx-2 btn-secundario"}
                                        btntype={"secondary"}
                                        onClick={props.onClose}
                                        disabled={props.loading}
                                    >
                                        Cancelar
                                    </Btn>
                                    <Btn
                                        size={small ? "md" : "lg"}
                                        bRadius={"22px"}
                                        mw={small ? "114px" :"150px"}
                                        className={"mx-2 btn-primario"}
                                        onClick={props.onConfirm}
                                        loading={props.loading}
                                    >
                                        Aceptar
                                    </Btn>
                                </Flex>
                            }
                        </div>
                        :
                        <div className={` wc gira`}>
                            {props.children}
                        </div>
                    }
                    {(props.closeMask && props.type == 'error')
                        ? <MaskClose onClick={ props.closeMask ? props.onClose : () => {}}/> : null}
                </Flex>

            </Flex>
        </CSSTransition>

    )
};


class ShowModal extends Component {



    render() {
        // React does *not* create a new div. It renders the children into `domNode`.
        // `domNode` is any valid DOM node, regardless of its location in the DOM.
        return ReactDOM.createPortal(
            <Modal {...this.props} >
                {this.props.children}
            </Modal>,
            document.getElementById("cont-modal")
        );
    }

}

export default React.memo(ShowModal);



