import React from "react";
import styled from "@emotion/styled";
import {colors} from "./../index";
import Flex from "../Flex";


const Btnt = styled.div`
    width:100%;
    max-width:${props => props.theme.mw};
    flex: ${props => props.theme.flex};
    position:relative;
    height:${props => props.theme.height}px;
    
    
    button{   
        background:       ${props => props.theme.bg};
        font-size:        ${props => props.theme.text}px;
        color:            ${props => props.theme.color};
        border:1px solid  ${props => props.theme.borde};
        width:100%;
        min-height: 40px;
        border-radius:    ${props => props.theme.bRadius};
        cursor:           ${props => props.theme.cursor};
        
        font-weight: ${props => props.theme.fontWeight} !important;
        font-family: ${props => props.theme.fontFamily}, sans-serif;
        
        &:hover{
            background:   ${props => props.theme.hoverColor};
            box-shadow: 0 4px 0 rgba(0,0,0,.3);
        }
      
    }
    
    
    .load{
        width:100%;
        position:absolute;
        top:0;
        left:0;
        height:100%;
        z-index:2;
        
        .circle{
            width:  ${props => props.theme.height - 4}px;
            height: ${props => props.theme.height - 4}px;
            border-radius:50%;
            border: 4px solid transparent;
            border-color: rgba(0,0,0,.2) rgba(0,0,0,.2) rgba(0,0,0,.2) ${props => props.theme.loading}; 
            animation: girar .5s ease-out infinite;
        }
    }
`;


function Btn(
    {
        btntype = "none",
        type,
        text,
        children,
        onClick,
        disabled,
        loading,
        bRadius = "6px",
        flex,
        className,
        mw,
        size = "lg",
        fontWeight = '400',
        fontFamily = 'Roboto Condensed'

    }) {

    disabled = loading ? loading : disabled;

    let sizes = {
        height: {
            xs: 20,
            sm: 30,
            md: 40,
            lg: 44,
            xl: 80,
        },
        text: {
            xs: 12,
            sm: 14,
            md: 16,
            lg: 20,
            xl: 30,
        }
    };

    const theme = {
        borde: disabled && !loading ? (btntype !== "reset" ? colors.grey03 : "transparent") : (loading || !disabled) && (btntype !== "reset" ? colors.rojo01 : "transparent"),
        bg: disabled ? (loading ? (btntype === "none" ? colors.rojo01 : "white") : (btntype === "none" || btntype === "secondary"? colors.grey03 : "white")) : (btntype === "none" ? colors.rojo01 : "white"),
        cursor: disabled && loading ? "wait" : disabled && !loading ? "not-allowed" : "pointer",
        bRadius: bRadius,
        color: disabled && !loading ? (btntype === "none" ? "white" : colors.grey03) : loading ? "rgba(0,0,0,.0)" : (btntype === "secondary" ? colors.grey03 : btntype === "none" ? "white" : colors.rojo01),
        loading: (btntype === "line" ? colors.verde03 : btntype === "reset" ? colors.verde03 : "white"),
        flex: flex,
        mw: mw,
        hoverColor: disabled ? (loading ? colors.rojo01 : colors.grey03) : (btntype === "line" || btntype === "reset") ? "#f1f1f2" : btntype === "secondary" ? colors.grey03 : colors.rojo01,
        height: sizes.height[size],
        text: sizes.text[size],
        fontFamily: fontFamily,
        fontWeight: fontWeight

    };

    return (
        <Btnt
            theme={theme}
            className={className}
        >
            <button
                onClick={onClick}
                disabled={disabled}
                type={type}
            >
                {children ? children : text}
            </button>

            {loading &&
            <Flex className="load" direction={"column"}>
                <div className="circle"></div>
            </Flex>
            }

        </Btnt>
    )
};

export default Btn;
