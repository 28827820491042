import styled from "@emotion/styled";
import Flex from "../UI/Flex";
import {colors} from "../UI";

export const Container = styled(Flex)`
    position:relative;
    min-height:100%;
    height:auto;
    background:url('/img/Background-01.svg');
   
    background-position: center; 
    background-size: cover;
    
    
    input, select{
        border-bottom:1px solid ${colors.rojo01}!important;
    }
    
    header{
        background:${colors.rojo01};
        color:white;
        width:100%;
        flex:0 0 45px;
        font-size:20px;
        font-weight:300;  
        padding:10px;  
    }
    
    .title{
        font-weight:bold;
        font-size:18px;
        line-height:24px;
        margin-bottom:20px;
    }
    
    .content{
        flex:0 0 auto;
        padding:15px 8px;
        padding-bottom:40px;
        overflow:hidden auto;
    }
    
    .card{
        border-radius : 10px;
        padding       : 21px;
        width         : 100%;
        max-width     : 540px;
     
        @media all and (min-width:500px){
            padding: 20px 40px;
        }

    }
    
     @media all and (min-height:720px){
            justify-content:center!important;
     }
    
    .action{
        margin-top: 20px;
    }
`;
