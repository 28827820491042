import React from "react";
import styled from "@emotion/styled";
import Title from "./../UI/title";
import Flex from "./../UI/Flex";
import Btn from "./../UI/Btn";
import {colors} from "../UI";
import {withRouter} from "react-router";
import Fade from "./../UI/fade";
import useGTM from "@elgorditosalsero/react-gtm-hook";

export const Container = styled(Flex)`
    position:relative;
    flex:1 0 50%;
    justify-content:flex-start;
    
    .icon{
        margin-top:15px;
        line-height:30px;
        font-size:35px;
        color:${colors.rojo01};
    }
        
    .ct{
        font-size:30px;
        
        @media all and (max-width:768px){
            font-size:16px;
        }
    }
    
    @media all and (min-height:600px) && (min-width:768px){
        justify-content:center;
    } 
`;

function Fallida(props) {
    const { sendDataToGTM } = useGTM();
    return (
        <Container className={"col-12 col-sm-10  mx-auto px-0"} >
            <Flex className={"wc  pt-5 "} alg={"flex-start"}>
                <div className="col-12 col-md-6">
                    <Flex flex={"1 0 100%"}  alg={"flex-start"}>
                        <Flex className={"icon "} flex={"0 0 30px"}>
                            <i className="icon-cancel-circle"> </i>
                        </Flex>
                        <Flex flex={"1 0 70%"} jc={"flex-start"} className={" pl-3 pl-md-4"}>
                            <Title margin={"0"}>Transacción {props.title==='Aceptada'?'exitosa':props.title}</Title>
                            <b className={"ct"}>{props.message}</b>
                        </Flex>
                    </Flex>
                    <Flex className={"pt-4 "} jc={"flex-end"}>
                       <Fade>
                           <Btn mw={"144px"} size={"md"} bRadius={"22px"}
                                onClick={()=>{
                                    props.history.push("/meter-plata")
                                    sendDataToGTM({
                                        eventCategory: 'Transaccion - Meter plata - Daviplata',
                                        eventAction: 'Click',
                                        eventLabel: 'Rechazada - Finalizar',
                                        eventValue: '',
                                        event: 'eventClick'
                                    })
                                }}
                           >
                               Finalizar
                           </Btn>
                       </Fade>
                    </Flex>
                </div>
                <div className="col-12 col-md-6 text-center d-none d-md-block">
                    <img src="/img/celu.png" alt="" className={" mx-auto"}/>
                </div>
            </Flex>
        </Container>
    )
}

export default withRouter(React.memo(Fallida));
