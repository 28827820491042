import React, {useState, useEffect} from "react";
import styled from "@emotion/styled";
import Flex from "../UI/Flex";
import Field from "../UI/field";
import Btn from "./../UI/Btn";
import Select from "./../UI/select";
import * as Yup from "yup";
import {Formik} from "formik";
import {connect} from "react-redux";
import {setGlobalData} from "../../store/actions/actionsCreators";
import {hanlderValue, isMobile} from "../helpers";
import ShowModal from "../UI/modal/ShowModal";
import Modal from "./modal";
import {GetComision, getCookie, ProcesarRecargaPago, setCookie} from "../../helper";
import {numberFormatSeparator, validEmail, bankLockRecharge, messageValidForm} from "../helpers";
import {withRouter} from "react-router";
import store from "../../store";
import {SET_STATE_GLOBAL} from "../../store/actions";
import useGTM from '@elgorditosalsero/react-gtm-hook'
import CaptchaComponent from '../captcha'


const Container = styled.div`
    position:relative;
`;

function Formulario(props) {


    const [bankLock, setBanks] = useState(bankLockRecharge);
    const [modal, setM] = useState("");
    const [message, setMessage] = useState("");
    const [processing, setProcesing] = useState(false);

    const [errorServer, setE] = useState({
        number: "",
        amount: ""
    });


    const RecargarS = Yup.object({
        number: Yup.string()
            .min(10, messageValidForm.phone)
            .required('error'),
        confirmNumber: Yup.string()
            .min(10, messageValidForm.phone)
            .oneOf([Yup.ref('number'), null], messageValidForm.confirm)
            .required('error'),
        amount: Yup.number()
            .min(10000, messageValidForm.min)
            .max(5000000, messageValidForm.max)
            .required('error'),
        email: Yup.string()
            .email(messageValidForm.email)
            .required('error'),
        bank: Yup.string()
            .min(4, messageValidForm.bank)
            .required('error')
    });

    const [data, SetData] = useState({
        number: "",
        confirmNumber: "",
        amount: "",
        email: '',
        bank: ""
    });

    const procesPSE = async (data) => {
        store.dispatch({
            type: SET_STATE_GLOBAL,
            payload: {
                modalLoading: true
            }
        });

        // const commision = isMobile() ? "1060" : "1070";
        const commision = "1070";

        let urlPSE = await ProcesarRecargaPago({
            daviplata: data.number.toString(),
            value: data.amount.toString(),
            bank: data.bank.toString(),
            email: data.email.toString(),
            idRequest: '',
        }, "recargar", commision, props.tokenCaptcha);

        if (urlPSE.status) {
            window.location.assign(urlPSE.url);
        } else {
            setM("error");
            setMessage(urlPSE.message);
            setProcesing(false);
            store.dispatch({
                type: SET_STATE_GLOBAL,
                payload: {
                    modalLoading: false
                }
            });
        }
    };

    const onChageField = (element, setFiel = () => {
    }) => {
        const {name, value} = element.target;
        let valueEnd = value;
        if (name == 'number' || name == 'confirmNumber' || name == 'amount' || name == 'bank') {
            valueEnd = parseInt(valueEnd) || '';
        }

        setFiel([name], valueEnd);
        SetData({...data, [name]: valueEnd});
    };

    const handlePaste = (e) => {

        e.preventDefault()
    }

    useEffect(() => {
        setCookie("confirm", false)
    }, []);
    const {sendDataToGTM} = useGTM();
    return (
        <Container className={" pb-xl-5 mb-lg-5"}>

            <Formik
                initialValues={{
                    number: "",
                    confirmNumber: "",
                    amount: "",
                    bank: "",
                    error: "",
                    email: ""
                }}
                validationSchema={RecargarS}
                onSubmit={async (values) => {
                    sendDataToGTM({
                        eventCategory: 'Proceso de pago - Daviplata',
                        eventAction: 'Click',
                        eventLabel: 'Información del Pago - Continuar',
                        eventValue: '',
                        event: 'eventClick'
                    })

                    setProcesing(true);

                    //OBTEN LA COMISIÓN

                    //si es mobile 1060 y si es desktop 1070 Para controlar la petición de la comisión.
                    // const comisionValue = isMobile() ? "1060" : "1070";
                    const comisionValue = "1070";
                    let Comision = await GetComision(comisionValue, values.amount);

                    if (Comision.status) {
                        await procesPSE(values);
                    } else {
                        setProcesing(false);

                        //SI EL ERROR ES POR EL MONTO
                        if (Comision.typeError === "monto") {
                            setM("error");
                            setMessage("DaviPlata excede tope.");
                            setE({...errorServer, amount: "Monto no permitido."})
                        }
                    }
                }}
            >
                {({errors, values, touched, setFieldValue, handleSubmit}) => (
                    <form className={"wc"} onSubmit={e => {

                        if (Object.keys(errors).length > 0) {
                            setM("error");
                            let message = errors.number ||
                                errors.confirmNumber ||
                                errors.amount ||
                                errors.bank ||
                                errors.email ||
                                "Los datos ingresados son incorrectos";
                            setMessage(message);
                        }
                        e.preventDefault();
                        handleSubmit()
                    }}>
                        <Flex className={"wc"} alg={"stretch"} jc={"space-between"}>
                            <Flex className={"col-12 col-md-6 px-0"}>
                                <Field
                                    label="¿A qué número DaviPlata quiere meter plata?"
                                    placeholder={"Número de celular"}
                                    value={values.number}
                                    onChange={e => {
                                        onChageField({
                                            target: {
                                                name: 'number',
                                                value: hanlderValue(e.target.value, 10)
                                            }
                                        }, setFieldValue);
                                    }}
                                    disabled={processing}
                                    error={(errors.number && touched.number) || errorServer.number}
                                    labelSize={"16px"}
                                    className={"mb-sm-3 mb-md-5  pr-md-2 "}
                                    fZise={"22px"}
                                    type={"tel"}

                                />
                                <Field

                                    label="Confirme el número DaviPlata"
                                    placeholder={"Confirme el número DaviPlata"}
                                    value={values.confirmNumber}
                                    onPaste={ e => {
                                        handlePaste(e)
                                    }}
                                    onChange={e => {
                                        onChageField({
                                            target: {
                                                name: 'confirmNumber',
                                                value: hanlderValue(e.target.value, 10)
                                            }
                                        }, setFieldValue);
                                    }}
                                    disabled={processing}
                                    error={errors.confirmNumber && touched.confirmNumber}
                                    labelSize={"16px"}
                                    className={"mb-sm-3 mb-md-5  pr-md-2 "}
                                    fZise={"22px"}
                                    type={"tel"}
                                />
                                <Field
                                    label="¿Cuánta plata quiere meter?"
                                    placeholder={"Valor"}
                                    value={numberFormatSeparator(values.amount, '.')}
                                    prefix={"$"}
                                    onChange={e => {
                                        onChageField({
                                            target: {
                                                name: 'amount',
                                                value: hanlderValue(e.target.value, 7)
                                            }
                                        }, setFieldValue);
                                    }}
                                    disabled={processing}
                                    error={(errors.amount && touched.amount) || errorServer.amount}
                                    labelSize={"16px pr-md-2 "}
                                    className={"mb-sm-3 mb-md-5 "}
                                    //ClveOptions={{ numeral: true, numeralDecimalMark: '.', prefix: '$', rawValueTrimPrefix: true, blocks: [3, 3]}}
                                    //
                                    fZise={"22px"}
                                />
                            </Flex>

                            <Flex className={"col-12 col-md-6 px-0 "} jc={"flex-start"} direction={"column"}>
                                <Flex flex={"1 0 auto"} className={"wc "} jc={"flex-start"} direction={"column"}>
                                    <Field
                                        label="¿Cuál es su correo electrónico?"
                                        placeholder={"Correo electrónico"}
                                        value={values.email}
                                        onChange={e => {
                                            onChageField({
                                                target: {
                                                    name: 'email',
                                                    value: validEmail(e.target.value)
                                                }
                                            }, setFieldValue);

                                        }}
                                        disabled={processing}
                                        error={(errors.email && touched.email) || errorServer.email}
                                        labelSize={"16px"}
                                        className={"mb-sm-3 mb-md-5  pl-md-2 wc email"}
                                        fZise={"22px"}
                                        flex={"0 0 auto"}
                                    />
                                    <Field
                                        label="¿Desde qué banco quiere meter plata?"
                                        disabled={processing}
                                        error={errors.bank && touched.bank}
                                        labelSize={"16px"}
                                        className={"mb-sm-3 mb-md-5 pl-md-2 wc "}
                                        flex={"0 0 auto"}
                                        fZise={"22px"}
                                        color={"#6c757d"}
                                    >
                                        <Select
                                            value={values.bank}
                                            resource={props.global.banks}
                                            valueLock={bankLock}
                                            renderValue={'bankCode'}
                                            placeholder={'Seleccione el banco...'}
                                            renderName={'bankName'}
                                            onChange={e => {
                                                onChageField({
                                                    target: {name: 'bank', value: e.target.value}
                                                }, setFieldValue);

                                                props.global.banks.map(element => {
                                                    if (element.bankCode === e.target.value) {
                                                        sendDataToGTM({
                                                            eventCategory: 'Transaccion - Meter plata - Daviplata',
                                                            eventAction: 'Click',
                                                            eventLabel: element.bankName,
                                                            eventValue: '',
                                                            event: 'eventClick'
                                                        })
                                                    }

                                                })

                                            }}
                                            disabled={processing}
                                        />

                                    </Field>
                                </Flex>
                                <Flex className={"col-12 mb-sm-3 mb-md-5  justify-content-md-end px-0 pl-md-3"}
                                      style={{alignSelf: "flex-end"}}>
                                      <CaptchaComponent/>
                                    <Btn
                                        bRadius={"22px"}
                                        mw={"150px"}
                                        size={"lg"}
                                        disabled={!(
                                            data.bank !== "" &&
                                            data.email !== "" &&
                                            parseInt(data.amount) > 0 &&
                                            parseInt(data.number) > 0 &&
                                            parseInt(data.confirmNumber) > 0
                                        ) || props.tokenCaptcha === ""}
                                        type={'submit'}
                                        loading={processing}
                                    >
                                        <strong>Continuar</strong>
                                    </Btn>
                                </Flex>
                            </Flex>
                        </Flex>
                    </form>)}
            </Formik>

            <ShowModal
                show={modal !== "" || props.global.modalComision}
                onClose={() => {
                    setM("");
                    props.setGlobalData({modalComision: false});
                }}
                noBtn={modal !== ""}
                onConfirm={async () => {
                    sendDataToGTM({
                        eventCategory: 'Proceso de pago - Daviplata',
                        eventAction: 'Click',
                        eventLabel: 'Advertencia tope PSE - Aceptar',
                        eventValue: '',
                        event: 'eventClick'
                    })
                    await procesPSE(data);
                }}
                loading={props.global.modalLoading}
                type={modal}
                hideClose={true}
                closeMask={true}
            >
                <Modal
                    textLeft
                    type={modal}
                    comision={props.global.comision}
                    message={message}
                />
            </ShowModal>
        </Container>
    )
}

const MSTprops = state => ({
    tokenCaptcha: state.global.tokenCaptcha,
    global: state.global
});
const MDTprops = {setGlobalData};

export default connect(MSTprops, MDTprops)(React.memo(Formulario));
