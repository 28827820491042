import React, {useEffect, useState} from 'react';
import Recarga from "./components/recarga";
import Pagar from "./components/pagar";
import Respuesta from "./components/Response";
import RecargaDaviplataV from "./components/recargarDaviplataV";
import ResponseApp from "./components/Response/responseApp";
import Toast from "./components/toast";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from "react-router-dom";
import PagarRespuesta from "./components/pagar/respuesta";
import {useHistory} from "react-router-dom";
import Loading from "./components/UI/loading";
import {getToken, setCookie} from "./helper";
import Favicon from "react-favicon";
import useGTM from '@elgorditosalsero/react-gtm-hook'


const device = GFG_Fun();

function GFG_Fun() {
    var res = "IOS";
    var userAgent = navigator.userAgent.toLowerCase();
    var Android = userAgent.indexOf("android") > -1;
    if (Android) {
        res = "ANDROID";
    }
    return res;
}

function App() {
    const {init, UseGTMHookProvider} = useGTM()
    const history = useHistory();

    const [logo, setLogo] = useState("https://multimedia.epayco.co/dashboard/login/img/favicon-32x32.png");

    useEffect(() => {
        init({id: process.env.REACT_APP_GTM_ID})
        const path = window.location.pathname;

        //Redireccionar path anterior.
        if(path === "/" || path === "/recargar"){
            window.location.href = "meter-plata"
        }

        let dominioConfigurado = process.env.REACT_APP_DOMINIO_CONFIGURADO;

        if (path === "/meter-plata" || path === "/meter-plata/respuesta" || path === "/recargaD" || path === "/recargaD/respuesta") {
            document.title = "Meter Plata - DaviPlata | Ingrese a la opción Meter Plata desde nuestra página - DaviPlata";
            setLogo("https://multimedia.epayco.co/vende/vende-davivienda/Favicon+Daviplata+(1)+(1).png");
            dominioConfigurado = process.env.REACT_APP_DOMINIO_CONFIGURADO;
        } else {
            document.title = "Pagos PSE";
            dominioConfigurado = process.env.REACT_APP_DOMINIO_CONFIGURADO_PAGAR;
        }

        if (path !== "/pagar") {
            getToken(dominioConfigurado, true);
            setCookie("dominioRecaudo", dominioConfigurado);
        }

        window.addEventListener('message', function (e) {
            if (e.data === "backPage") {
                if (history.location.pathname === "/recargaD/respuesta") {
                    history.push("/recargaD");

                    if (device === 'ANDROID') {
                        window.Android.postMessage('true')
                    } else {
                        window.webkit.messageHandlers.getStoredData.postMessage('true')
                    }
                }
                if (history.location.pathname === "/recargaD") {
                    if (device === 'ANDROID') {
                        window.Android.postMessage('false')
                    } else {
                        window.webkit.messageHandlers.getStoredData.postMessage('false')
                    }
                }
            }
        });

    }, []);


    return (
        <div className={"main-container notranslate"}>
            <Favicon url={logo}/>
            <Router>
                <React.Fragment>
                    <Toast/>
                    <Switch>
                        <Route exact path="/meter-plata" render={() => <Recarga/>}/>
                        <Route exact path="/meter-plata/respuesta" render={() => <Respuesta/>}/>
                        

                        <Route exact path="/pagar" render={() => <Pagar/>}/>
                        <Route exact path="/pagar/respuesta" render={() => <PagarRespuesta/>}/>

                        <Route exact path="/recargaD" render={() => <RecargaDaviplataV/>}/>
                        <Route exact path="/recargaD/respuesta" render={() => <ResponseApp/>}/>
                    </Switch>
                </React.Fragment>
            </Router>
            <Loading/>
        </div>
    );
}


export default App;
