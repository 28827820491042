export const colors = {
    white:'#F9F9F9',
    grey01:'rgba(0, 0, 0, 10%)',
    grey02:"#D1D1D1",
    grey03:'#7F7F7F',
    grey04:'#707070',

    black01:'#343537',
    black02:'#272727',
    black03:'#000000',

    verde: '#009688',

    rojo01: '#DD0014',
    errorlabel: '#DD141D',
    errorbg: '#F8EDED'
};
