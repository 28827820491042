import React, {useEffect, useState} from "react";
import Header from "./../header";
import {connect} from "react-redux";
import Flex from "./../UI/Flex";
import {TitleStatus, Itenvalue, ButtonB, BtnDownload} from "../Response/helpers";
import {TitlePagar} from "./index";
import {Container} from "./styles";
import {PDFDownloadLink} from '@react-pdf/renderer';
import moment from "moment";
import axios from "axios";
import {bool} from "yup";
import {withRouter} from "react-router";
import {Pdf} from "./Pdf";
import {numberFormatSeparator} from "../helpers";
import {getCookie, removeCookies, getToken} from "../../helper";

function PagarRespuesta({history}) {

    const [Data, setState] = useState({
        text: "",
        loading: true,
        fallida: false,
        trx: {}
    });

    let handler = x => setState({...Data, ...x});

    const shareData = {
        title: 'Comprobante de pago',
        text: `Comprobande de pago/recarga de daviplata, fecha  ${moment().format("DD/MM/YYYY")}`,
        url: 'https://developer.mozilla.org',
    };
    let share = async () => {
        try {
            await navigator.share(shareData);
            setState({...Data, text: 'MDN shared successfully'})
        } catch (err) {
            setState({...Data, text: 'Error: ' + err})
        }
    };


    const publiKey = process.env.REACT_APP_PUBLIC_KEY_PAGAR;

    let ConsultaPSE = async () => {

        let transactionID = getCookie("transactionID");
        let token = await getToken(getCookie('dominioRecaudo'));

        let data = {
            "transactionID": transactionID
        };

        axios({
            data: data,
            url: ` ${process.env.REACT_APP_BASE_URL_REST_RECAUDO}/api/apify/payment/pse/transaction`,
            method: "post",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${token}`
            }
        }).then(response => {
            consult();
        }).catch(error => {
            consult();
        });
    };

    const consult = () => {

        let dataResponse = {
            status: bool(),
            data: {},
            message: "",
        };
        let refPayco = getCookie("refPayco");

        let url = process.env.REACT_APP_SECURE + "?public_key=" + publiKey + "&ref_payco=" + refPayco;
        axios.get(url)
            .then(responseTransaction => {

                if (responseTransaction.status === 200 && responseTransaction.data.success) {
                    let data = responseTransaction.data.data;
                    let paymentData = getCookie("paymentData");
                    handler({
                        loading: false,
                        trx: {
                            amount: data.x_amount,
                            cus: data.x_approval_code,
                            bank: data.x_bank_name,
                            hola: data.x_cod_transaction_state,
                            currency: data.x_currency_code,
                            idClient: data.x_cust_id_cliente,
                            ip: data.x_customer_ip,
                            description: data.x_description,
                            date: data.x_fecha_transaccion,
                            franchise: data.x_franchise,
                            refPayco: data.x_ref_payco,
                            state: data.x_response,
                            trxId: data.x_transaction_id,
                            comision: getCookie("commission") || 0,
                            number: paymentData.cellPhoneDaviplata,
                            urlResponse: paymentData.urlResponse + "?ref_payco=" + paymentData.invoiceId,
                            loading: false,
                            client: paymentData.client
                        },
                        fallida: data.x_response === "Fallida" || data.x_response === "Rechazada" || data.x_response === "Abandonada"
                    });

                } else {
                    dataResponse.status = true;
                    handler({fallida: true, loading: false})
                }
            }).catch(error => {

            handler({fallida: true, loading: false});
        });
    };

    useEffect(() => {
        ConsultaPSE();
    }, []);

    return (
        <Container>
            <div className="contHeader mb-2 mb-lg-0">
                <Header/>
            </div>
            {Data.loading ?
                <div className={"text-center py-5"}>
                    <h2> Procesando... </h2>
                </div>
                :
                <>{Data.fallida ?

                    <div className="wc px-3">
                        <TitlePagar> Información de pago</TitlePagar>

                        <Flex className="card pb-5" alg={"flex-start"} jc={"flex-start"}>
                            <TitleStatus
                                status={false}
                                text={"Pago rechazado"}
                                subtext={"Intente nuevamente"}
                            />
                            <ButtonB text={"Finalizar"} y={"18px"} onClick={() => {
                                window.location.assign(Data.trx.urlResponse);
                                removeCookies();
                            }}/>
                        </Flex>
                    </div>
                    :
                    <>
                        < TitlePagar> Información de pago.</TitlePagar>
                        <div className="wc px-3">
                            <Flex className="card pb-5" alg={"flex-start"} jc={"flex-start"}>
                                <React.Fragment>
                                    <TitleStatus
                                        status={true}
                                        text={`Pago ${Data.trx.state === 'Aceptada' ? 'exitoso' : Data.trx.state}`}
                                        subtext={""}
                                        className={"mb-2"}
                                        pendiente={Data.trx.state === "Pendiente"}
                                    />

                                    <Itenvalue className={"bb"} name={"¿A quién le pagó?"} value={Data.trx.client}/>
                                    <Itenvalue className={"bb"} name={"Valor pagado"}
                                               value={"$" + numberFormatSeparator(Data.trx.amount.toString(), '.') + " COP"}/>
                                    <Itenvalue className={"bb"} name={"Referencia"} value={Data.trx.refPayco}/>
                                    <Itenvalue name={"Fecha y hora de la transacción"}
                                               value={moment(Data.trx.date).format("DD/MM/YYYY - HH:mm:ss")}/>

                                    <div className={" wc"}>
                                        <PDFDownloadLink
                                            document={
                                                <Pdf
                                                    cliente={Data.trx.client}
                                                    monto={"$ " + numberFormatSeparator(Data.trx.amount.toString(), '.')}
                                                    numero={Data.trx.number}
                                                    referencia={Data.trx.refPayco}
                                                    fecha={moment(Data.trx.date).format("DD/MM/YYYY - HH:mm:ss")}
                                                    costo={"$ " + Data.trx.comision}
                                                    transaccion={Data.trx.state}
                                                    cus={Data.trx.cus}
                                                    motivo={"Compra Catalogo"}
                                                    nAprovacion={Data.trx.trxId}
                                                    direcionIp={Data.trx.ip}
                                                    bank={Data.trx.bank}
                                                />
                                            }
                                            fileName="Informacion_de_pago.pdf"
                                        >
                                            {({blob, url, loading, error}) => (loading ? 'Loading document...' :
                                                    <BtnDownload className={"mx-auto my-2"}/>
                                            )}
                                        </PDFDownloadLink>
                                    </div>

                                    <ButtonB
                                        text={"Finalizar"}
                                        y={"18px"}
                                        onClick={() => {
                                            window.location.assign(Data.trx.urlResponse);
                                            removeCookies();
                                        }}
                                    />
                                </React.Fragment>

                            </Flex>
                        </div>
                    </>
                }
                </>
            }
        </Container>
    )
}

const MSTprops = state => ({global: state.global});

export default connect(MSTprops, null)(withRouter(React.memo(PagarRespuesta)));
