import React, {useEffect, useState} from "react";
import styled from "@emotion/styled";
import {CSSTransition} from 'react-transition-group';
import {setGlobalData} from "./../../store/actions/actionsCreators";
import {connect} from "react-redux";
import "./styles.scss";
import {colors} from "../UI";
import Flex from "./../UI/Flex";
import {isMobile} from "../helpers";


const Container = styled.div`
    width:100%;
    position:fixed;
    max-width:560px;
    top:${props => props.top};
    left:50%;
    transform:translateX(-50%);
    background:${props => props.bg};
    color:${props => props.color};
    z-index:99999999;
    border-radius: 0 0 10px 10px;
       
    
    .content{   
        font-size:13px;
        font-family:'Roboto';
        font-weight:300;
    }
    
    .icon{
        color:${colors.rojo01};
        font-size:18px;
    }
    
    .base-cose{
      height: 15px;
      width: 100px;
      padding: 5px 5px;
      
      cursor: pointer;
      position:absolute;
      bottom: 0px;
      left: calc(50% - 30px);
      
      // background-color: orange; // Para probrar el contenedor 
      
      & > div{
        height: 5px;
        width: 42px;
        border-radius: 10px;
        background-color: #575757;
        display: block;
        margin: auto;
      }
    }
    
    @media all and (min-width: 1024px){
        top:15px;
        border-radius: 12px;
        font-size:18px;
    }    
`;

function Toast(props) {

    const [state, setState] = useState(false);

    const closeToast = (state) => {
        props.setGlobalData({
            toast: {
                status: !state,
            }
        });
    }

    useEffect(() => {
        if (props.toast.status) {
            setTimeout(function () {
                closeToast(props.toast.status);
            }, 10000)
        }

        if(isMobile()){
            setState(true);
        }

    }, [props.toast]);

    return (
        <CSSTransition
            in={props.toast.status}
            timeout={300}
            classNames="alert"
            unmountOnExit
        >
            <Container bg={props.blanco ? "#fff" : "#000"} color={props.blanco ? "#000" : "#fff"} top={state?"50px":"0px"}>
                <div className="col-12 p-3 px-xl-4  content">
                    <Flex className={"px-0 col-12 col-xl-10 mx-auto"}>
                        <Flex flex={"20px"}>
                            <i className={"icon icon-info"}> </i>
                        </Flex>
                        <Flex flex={"1 0 70%"} jc={"flex-start"} className={"mensaje"}>
                            {props.toast.message}
                        </Flex>
                    </Flex>
                    <div className={'base-cose'} onClick={() => closeToast(props.toast.status)}>
                        <div/>
                    </div>
                </div>
            </Container>
        </CSSTransition>
    )
}

const MSTprops = state => ({toast: state.global.toast});
const MDTprops = {setGlobalData};


export default connect(MSTprops, MDTprops)(React.memo(Toast));
