import React, {useState, useEffect} from "react";
import Field from "./../UI/field";
import Flex from "./../UI/Flex";
import {Formik} from "formik";
import * as Yup from "yup";
import Btn from "./../UI/Btn";
import Header from "./../header";
import ShowModal from "../UI/modal/ShowModal";
import {Container} from "./styles";
import {connect} from "react-redux";
import {
    hanlderValue,
    numberFormatSeparator,
    messageValidForm,
    validEmail,
    bankLockPayment,
    addGAEvent
} from "../helpers";
import Mcont from "./modalContent";
import styled from "@emotion/styled";
import {setGlobalData} from "../../store/actions/actionsCreators";
import axios from "axios";
import {Setoast, GetTBanks, ProcesarRecargaPago, getToken, setCookie} from "../../helper";
import Fade from "./../UI/fade";
import Loading from "../UI/loading";
import Select from "../UI/select";


export const TitlePagar = styled.div`
        margin-bottom:30px;
        font-size:30px;
        font-weight:bold;
        width:100%;
        text-align:center;
        @media all and (max-width: 425px){
          display: none;
          font-size:20px;
          line-height:1.3em;
        }
`;

function Pagar(props) {

    let small = window.innerWidth < 768;

    const PagarSchema = Yup.object().shape({
        receptor: Yup.string()
            .required(messageValidForm.receptor),
        correo: Yup.string()
            .email(messageValidForm.email)
            .required('required'),
        monto: Yup.number()
            .max(5000000, messageValidForm.max)
            .required('required'),
        banco: Yup.string()
            .min(4, messageValidForm.bank)
            .required('required'),
    });

    const [bankLock] = useState(bankLockPayment);
    const [modal, SetM] = useState({status: false, message: ""});
    const [processing, SetP] = useState(false);
    const [loading, SetLoading] = useState(true);
    const [state, setState] = useState({
        daviplata: "",
        terminal: false,
        terminalNotFound: false
    });

    const [initialData, SetInitialData] = useState({
        correo: "",
        receptor: "",
        monto: "",
        banco: "",
    });
    const [Disabled, setDisabled] = useState({
        correo: false,
        receptor: false,
        monto: false,
    });

    let handlerS = x => {
        setState({...state, ...x})
    };

    const procesPSE= async (data)=>{

        let urlPSE = await ProcesarRecargaPago({
            daviplata: state.terminal ? state.daviplata : data.receptor,
            value: data.monto.toString(),
            bank: data.banco.toString(),
            email: data.correo.toString(),
            invoice: state.terminal ? state.invoiceId : ""
        }, "pagar");
        if (urlPSE.status) {
            window.location.assign(urlPSE.url);
        } else {
            SetM({status: true, message: urlPSE.message})
            SetP(false)
        }
    }

    const getData = async () => {
        let search = window.location.search;
        let dominioConfigurado = process.env.REACT_APP_DOMINIO_CONFIGURADO_PAGAR;
        setCookie('dominioRecaudo', dominioConfigurado)
        let terminal = new URLSearchParams(search).get("terminal");
        await GetTBanks();
        let token = await getToken(dominioConfigurado);

        if (terminal && token) {
            try {
                axios({
                    data: {"terminal": terminal},
                    url: process.env.REACT_APP_BASE_URL_REST_RECAUDO + "/api/apify/link/paymentpse/show",
                    method: "post",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        "Authorization": `Bearer ${token}`
                    }
                })
                    .then(response => {
                        SetLoading(false);

                        if (response.status === 200 && response.data.success) {
                            let {
                                cellPhone, client, clientId, docNumber, docType, emailPayment, invoiceId, lastName, name, product, totalPayment, urlResponse, cellPhoneDaviplata
                            } = response.data.data;

                            handlerS({
                                terminal: true,
                                daviplata: cellPhoneDaviplata,
                                invoiceId
                            });

                            // GUARDAR DATA EN LOCALSTORAGE
                            setCookie("paymentData",
                                {
                                    cellPhone,
                                    client,
                                    clientId,
                                    docNumber,
                                    docType,
                                    emailPayment,
                                    invoiceId,
                                    lastName,
                                    name,
                                    product,
                                    totalPayment,
                                    urlResponse,
                                    cellPhoneDaviplata
                                })
                            SetInitialData({
                                ...initialData,
                                correo: emailPayment,
                                receptor: client,
                                monto: totalPayment,
                            });
                            setDisabled({
                                correo: emailPayment ? true : false,
                                receptor: client ? true : false,
                                monto: totalPayment ? true : false,
                            })
                        }else{
                            handlerS({terminalNotFound: "Link no valido"});
                        }
                    }).catch(error => {
                    handlerS({terminal: true});
                    SetLoading(false);
                    Setoast("ERROR: no se pudo consultar la terminal. " + error.message);
                })
            } catch (e) {
                SetLoading(false);
                Setoast("ERROR: no se pudo consultar la terminal. " + e.message);
            }
        } else {
            SetLoading(false);
            Setoast("Error no se logro obtener la terminal o el token es invalido, por favor recargar la página para volver a consultar.");
        }
    };

    const onChageField=(element, setFiel= ()=>{})=>{
        const { name, value } = element.target;
        let valueEnd = value;
        if(name == 'monto' || name == 'confirmNumber' || name == 'amount' || name == 'banco'){
            valueEnd = parseInt(valueEnd) || '';
        }
        setFiel([name], valueEnd);
        SetInitialData({ ...initialData, [name]: valueEnd });
    }


    useEffect(() => {
        getData();
    }, []);


    return (
        <Container className={"text-center"}>
            <div className="contHeader mb-2 mb-lg-0">
                <Header/>
            </div>
            {props.global.loading ?
                <Loading noBg only/>
                :
                <>
                    {
                        loading ?
                            <Loading noBg only/> :
                            <>
                                <TitlePagar>Información de pago</TitlePagar>
                                <Fade>
                                    <div className="wc px-2 px-sm-3  pb-5">
                                        <div className="card">
                                        {
                                            state.terminalNotFound ? 
                                            <p className={'title'}>{state.terminalNotFound}</p>
                                            :
                                            <React.Fragment>
                                            <p className={'title'}> Complete la información para pagar </p>
                                            <Formik
                                                enableReinitialize={true}
                                                initialValues={{ ...initialData}}
                                                validationSchema={PagarSchema}
                                                onSubmit={async values => {
                                                    addGAEvent('Proceso de pago - Daviplata','Información del Pago - Continuar');
                                                    SetP(true);
                                                    await procesPSE(values);

                                                }}
                                            >
                                                {({errors, touched, setFieldValue, handleSubmit, values}) => (
                                                    <form onSubmit={e => {

                                                        if (Object.keys(errors).length > 0) {
                                                            let message = errors.receptor ||
                                                                errors.monto ||
                                                                errors.banco ||
                                                                "Los datos ingresados son incorrectos";
                                                            SetM({status: true, message: message});
                                                        }
                                                        e.preventDefault();
                                                        handleSubmit()
                                                    }}>
                                                        <div>
                                                            <label className={"label-text"}>¿A quién le va a
                                                                pagar?</label>
                                                            <div className={"text-label"}>
                                                                {values.receptor}
                                                            </div>
                                                        </div>
                                                        <hr style={{color: "rgba(0, 0, 0, 10%)"}}></hr>
                                                        <Field
                                                            line
                                                            labelSize={"16px"}
                                                            label={"¿Cuanta plata va a pagar?"}
                                                            onChange={e => {
                                                                onChageField({
                                                                    target:{
                                                                        name: 'monto',
                                                                        value: hanlderValue(e.target.value, 7)
                                                                    }
                                                                }, setFieldValue);

                                                            }}
                                                            value={numberFormatSeparator(values.monto, '.')}
                                                            disabled={processing || Disabled.monto}
                                                            before
                                                            error={(errors.monto && touched.monto) && errors.monto}
                                                            font={"Roboto"}
                                                            className={"mb-4"}
                                                            weight={"500"}
                                                            type={"tel"}
                                                            //ClveOptions={{ numeral: true, numeralDecimalMark: '.', delimiter: ',', rawValueTrimPrefix: true, blocks: [3, 3]}}
                                                            //Clve
                                                        />
                                                        <Field
                                                            line
                                                            labelSize={"16px"}
                                                            label={"¿Cual es su correo electrónico?"}
                                                            onChange={e => {
                                                                onChageField({
                                                                    target:{
                                                                        name: 'correo',
                                                                        value: validEmail(e.target.value)
                                                                    }
                                                                }, setFieldValue);
                                                            }}
                                                            value={values.correo}
                                                            error={(errors.correo && touched.correo) && errors.correo}
                                                            disabled={processing || Disabled.correo}
                                                            font={"Roboto"}
                                                            className={`mb-4 ${initialData.correo !== "" ? "dn" : ""}`}
                                                            weight={"500"}
                                                        />
                                                        <Field
                                                            line
                                                            labelSize={"16px"}
                                                            label={"¿Desde qué banco va a pagar?"}
                                                            disabled={processing}
                                                            error={(errors.banco && touched.banco) && errors.banco}
                                                            font={"Roboto"}
                                                            className={"mb-2 pagar"}
                                                            weight={"500"}
                                                        >
                                                            <Select
                                                                value={values.banco}
                                                                resource={props.global.banks}
                                                                valueLock={bankLock}
                                                                renderValue={'bankCode'}
                                                                placeholder={'Seleccione el banco...'}
                                                                renderName={'bankName'}
                                                                onChange={e => {
                                                                    onChageField({
                                                                        target:{
                                                                            name: 'banco',
                                                                            value: validEmail(e.target.value)
                                                                        }
                                                                    }, setFieldValue);
                                                                }}
                                                                disabled={processing}
                                                            />

                                                        </Field>

                                                        <Flex className={"action"}>
                                                            <Btn
                                                                type={"submit"}
                                                                bRadius={"22px"}
                                                                size={small ? "md" : "lg"}
                                                                mw={"160px"}
                                                                loading={processing}
                                                                disabled={!Object.values(initialData).every(item => item !== '') || processing}
                                                            >
                                                                Continuar
                                                            </Btn>
                                                        </Flex>
                                                    </form>
                                                )}
                                            </Formik>
                                            </React.Fragment>
                                        }
                                            
                                        </div>
                                    </div>
                                </Fade>
                            </>
                    }
                </>
            }
            <ShowModal show={modal.status} noBtn={true} cancel={false} hideClose>
                <Mcont continuar={() =>{
                    SetM({status: false, message: ""})
                    addGAEvent('Proceso de pago - Daviplata','Advertencia tope PSE - Aceptar');
                }
                } message={modal.message}/>
            </ShowModal>
        </Container>
    )
}

const MSTprops = state => ({global: state.global});
const MDTprops = {setGlobalData};

export default connect(MSTprops, MDTprops)(React.memo(Pagar));
