import React from "react";
import styled from "@emotion/styled";
import Title from "./../UI/title";
import Formulario from "./formulario";
import Header from "./../header";
import Flex from "../UI/Flex";
import {connect} from "react-redux";
import Loading from "./../UI/loading";
import Fade from "./../UI/fade";


const Container = styled(Flex)`
    position:relative;
    width:100%;
    min-height:100vh;
   
   .container{
        flex: 1 0 70%;
        justify-content:center;
        padding:0 20px 15px;
       
        @media all and (max-height:400px) && (max-width:767px) {
            justify-content:flex-start;
        } 
   }

`;



function Recarga(props) {

    return (
        <Container direction={"column"} jc={"flex-start"}>

            <Header type={"rojo"}/>

            {
                props.global.loading ?
                    <Loading noBg only dark /> :

                        <Flex className="container" direction={"column"}>
                            <Title className={"text-center"}>
                                <span className={"ct"}>Meter Plata</span> a DaviPlata
                            </Title>
                            <Fade>
                                <Formulario/>
                            </Fade>
                        </Flex>

            }
        </Container>
    )
}

const MSTrpops = state => ({global:state.global});

export default connect(MSTrpops)(React.memo(Recarga));
