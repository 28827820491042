
import React from "react";
import {ItenValueStl, TitleCont,ButtonBS,ButtonD} from "./styles";
import Flex from "../UI/Flex";
import Btn from "../UI/Btn";
import {colors} from "../UI";

export const TitleStatus = ({pendiente,text,status,subtext,className})=>{
    return(
        <TitleCont jc={"flex-start"} alg={"flex-start"} className={className} color={status ? (pendiente ? colors.rojo01: "#07A033") : colors.rojo01} >
            {!pendiente &&
            <Flex flex={"0 0 30px"}>
                <i className={`icon-${status ? "check-circle":"cancel-circle"}`}> </i>
            </Flex>}
            <Flex flex={"1 0 60%"} jc={"flex-start"} className={"pl-2"}>
                <p className={"mb-0"}>{text}</p>
                <span className={"subtext"}>{subtext}</span>
            </Flex>
        </TitleCont>
    )
};

export const Itenvalue = ({name,value,className})=>{
    return(
        <ItenValueStl className={`wc iv text-left ${className}`}>
            <b>{name}</b>
            <p className={"mb-0"}>{value}</p>
        </ItenValueStl>
    )
};

export const ButtonB = ({onClick,text,disabled,y})=>{
    return(
        <ButtonBS y={y}>
            <Btn
                size={"md"}
                onClick={onClick}
                bRadius={"22px"}
                mw={"144px"}
                className={"mx-auto"}
            >
                {text}
            </Btn>
        </ButtonBS>
    )
};

export const BtnDownload = ({onClick,className})=>{
    return(
        <ButtonD onClick={onClick} w={"150px"} className={className} >
            <Flex className={"wc"}>
                <img src="/img/download.svg" alt="" className={"mr-2"}/>
                <span>Descargar</span>
            </Flex>
        </ButtonD>
    )
};
