import React from "react";
import styled from "@emotion/styled";
import {CSSTransition} from "react-transition-group";
import {colors} from "./index";
import {connect} from "react-redux";
import Flex from "./Flex";

const Container = styled(Flex)`
    position:fixed;
    top:0;
    left:0;
    z-index:99;
    width:100%;
    height:100%; 
    background:${props=> props.bg};
   
    .loadC{
        width: 100px;
        height: 100px;
        border-radius:50%;
        border: 8px solid transparent;
        border-color: ${props => props.loadLine} ${props => props.loadLine} ${props => props.loadLine} ${colors.rojo01} ;
        animation: girar 1s cubic-bezier(0.075, 0.820, 0.165, 1.000) infinite;
        
        @media all and (max-width:768px){
            width: 50px;
            height: 50px;
            border: 4px solid transparent;
            border-color: ${props => props.loadLine} ${props => props.loadLine} ${props => props.loadLine} ${colors.rojo01};
        }
    }
`;

function Loading(props) {
    return (
        <>
            {props.only ?
                <Container  direction={"column"} bg={props.noBg ? "transparent" : "rgba(0,0,0,.5)"} loadLine={props.dark ? "rgba(0,0,0,.2)" : "white"}>
                    <div className="loadC">
                    </div>
                </Container>
                :
                <CSSTransition
                    in={props.global.loading}
                    timeout={600}
                    classNames="fade"
                    unmountOnExit
                >
                    <Container direction={"column"} loadLine={props.dark ? "rgba(0,0,0,.2)" : "white"}>
                        <div className="loadC">
                        </div>
                    </Container>
                </CSSTransition>

            }
        </>
    )
}

const MSTprops = state => ({global:state.global});

export default connect(MSTprops)(React.memo(Loading));
