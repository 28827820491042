import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import store from "../../store";
import { SET_STATE_GLOBAL } from "../../store/actions";
import styled from "@emotion/styled";

const BaseCaptcha = styled.div`
  margin: 15px auto;
  display: flex;
  justify-content: center;
`;

const CaptchaComponent = ({ className = "" }) => {
  const recaptchaRef = React.useRef();
  const onChange = (token) => {
    store.dispatch({
      type: SET_STATE_GLOBAL,
      payload: { tokenCaptcha: token },
    });
  };
  return (
    <BaseCaptcha className={className}>
      <ReCAPTCHA
        ref={recaptchaRef}
        onChange={onChange}
        sitekey={`6Ld96BsTAAAAALXvSsGX-7KdjoXRuXcFrMx4xB-O`}
      />
    </BaseCaptcha>
  );
};

export default CaptchaComponent;
