import React, {Fragment, memo} from "react";
import PropTypes from "prop-types"

const validLockBanks =(locks, bank)=>{
    return locks.length ? !(locks.filter(lock => lock == bank).length) : true
}

const Select =(
    {
        id,
        name,
        value,
        onChange,
        classname,
        disabled= ()=>{},
        resource=[],
        placeholder,
        renderName='name',
        renderValue='key',
        valueLock=[]
    }
)=>{
    return <Fragment>
        <select
            id={id}
            name={name}
            value={value}
            disabled={disabled}
            onChange={!disabled ? onChange : ()=>{}}
            className={classname}

        >
            <option value=""> {placeholder}</option>
            {
                resource.length > 0 && resource.map((item, index) => {
                    return validLockBanks(valueLock, item[renderValue]) ? <option value={item[renderValue]} key={index}>{item[renderName]}</option> : '';
                })
            }
        </select>
    </Fragment>;
}

Select.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    renderName: PropTypes.string,
    renderValue: PropTypes.string,
    value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    classname: PropTypes.string,
    onChange: PropTypes.func,
    resource: PropTypes.array,
    valueLock: PropTypes.array
}

Select.defaultProps = {
    id: "",
    name: "",
    value: "",
    renderName: 'name',
    placeholder: 'Seleccione una opción...',
    renderValue: 'id',
    classname: '',
    onChange: ()=>{},
    resource: [],
    valueLock: []
}

export default memo(Select);