import React from "react";
import styled from "@emotion/styled";
import Btn from "./../UI/Btn";
import Flex from "../UI/Flex";

const Container = styled.div`
    position:relative;
    padding: 20px;
    
    .action{
        position:absolute;
        bottom:0;
        left:0;
        width:100%;
        transform:translateY(17px);
    }
    
    .texto{
        padding:15px 0 20px;
        margin:auto;
        
        @media all and (min-width:768px){
            font-size:24px;
        }
    }
`;

function Mcont({continuar, cancelar, message}) {
    return (
        <Container>
            <div className="texto text-center ">
                {message}
            </div>
            <Flex className="action text-center" jc={"apace-around"}>
                {cancelar &&
                <Btn
                    btntype={"line"}
                    onClick={cancelar}
                    className={"md mx-auto"}
                    mw={"144px"}
                    bRadius={"22px"}
                    size={"md"}
                >
                    Cancelar
                </Btn>
                }
                <Btn
                    onClick={continuar}
                    className={"md mx-auto"}
                    mw={"144px"}
                    bRadius={"22px"}
                    size={"md"}
                >Aceptar
                </Btn>
            </Flex>
        </Container>
    )
}

export default React.memo(Mcont);
