import {
    SET_PAGOS_DATA
} from "../actions";

const initialState = {};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_PAGOS_DATA:
            return {...state, ...action.payload};
        default:
            return state
    }
}
