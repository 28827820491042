import React, {useState} from "react";
import styled from "@emotion/styled";
import Title from "./../UI/title";
import Flex from "./../UI/Flex";
import Btn from "./../UI/Btn";
import {colors} from "../UI";
import moment from "moment";
import {PDF} from "../recarga/PDF";
import {PDFDownloadLink} from '@react-pdf/renderer';
import {withRouter} from "react-router";
import Fade from "./../UI/fade";
import {numberFormatSeparator} from "../helpers";
import useGTM from "@elgorditosalsero/react-gtm-hook";
import {removeCookies, setCookie} from "../../helper";

const Container = styled(Flex)`
    position:relative;
    flex:1 0 60%;
    width:100%;
    
    .ancho{
        max-width:636px;
        border-radius:20px;
        background:white;
        padding:40px 32px;
        
        .download{
            background:transparent;
            font-size:22px;
            
            i{
                color:${colors.rojo01};
            }
        }
        
        @media all and (max-width:768px){
            background:transparent;
            padding:5px;
            
             .download{
                font-size:16px;
             }
        }
    }  
    
 
`;

const ContainerItem = styled(Flex)`
    position:relative;
    font-weight:700;
    color:black;
    
    .value{
        color:${props => props.color};
        text-align:right;
    }
    
    
    @media all and (min-width:998px){
        font-size:24px;      
    }
   
`;

const ItemDetail = ({name, value, color, className}) => {
    return (
        <ContainerItem className={`wc mb-2 ${className}`} color={
            (typeof color !== "undefined" && typeof color !== "string")
                ? colors.rojo01 : (typeof color == "string") ? color : colors.black03
        }>
            <Flex className="name  col-7 pl-0" jc={"flex-start"}>{name}</Flex>
            <Flex className="value col-5 p-0" jc={"flex-start"}>{value}</Flex>
        </ContainerItem>
    )
};


function ResponseDetail({trx, history}) {

    let {
        amount,
        cus,
        ip,
        date,
        refPayco,
        state,
        trxId,
        comision,
        number,
        docType,
        docNumber
    } = trx;

    let small = window.innerWidth < 768;

    const [State, setState] = useState({
        text: ""
    });

    let navigator = window.navigator;

    const shareData = {
        title: 'Comprobante de pago',
        text: `Comprobande de pago/recarga de daviplata, fecha  ${moment().format("DD/MM/YYYY")}`,
        url: 'https://developer.mozilla.org',
    };

    let share = async () => {
        try {
            await navigator.share(shareData);
            setState({...state, text: 'MDN shared successfully'})
        } catch (err) {
            setState({...state, text: 'Error: ' + err})
        }
    };

    const {sendDataToGTM} = useGTM();
    return (

        <Container className={"wc"} direction={"column"}>
            <Flex className={"col-12 col-sm-11 col-xl-10 mx-auto  px-0 "} alg={"flex-end"}>
                <div className="col-12 col-md-7 col-lg-7 px-xl-none ">
                    <Title>Transacción {state === 'Aceptada' ? 'exitosa' : state.toLowerCase()}</Title>
                    <Fade>
                        <div className="ancho">
                            <ItemDetail value={"$" + numberFormatSeparator(amount.toString(), '.')}
                                        name={"¿Cuánta plata metió?"}
                                        color/>
                            <ItemDetail
                                value={number}
                                name={"Número DaviPlata"}
                                color={"#2F303275"}/>
                            <ItemDetail
                                value={refPayco}
                                name={"Referencia"}
                                color={"#2F303275"}/>
                            <ItemDetail
                                value={moment(date).format("DD/MM/YYYY - HH:mm")}
                                name={"Fecha transacción"}
                                color={"#2F303275"}/>
                            <ItemDetail
                                value={"$" + comision}
                                name={"Costo de la transacción"}
                                color={"#2F303275"}/>


                            <Flex className={"wc pt-4"}>
                                {state !== "Pendiente" &&
                                <div className={"wc col-6 px-0 position-relative"}>
                                    <PDFDownloadLink
                                        document={
                                            <PDF
                                                monto={"$ " + numberFormatSeparator(amount.toString(), '.')}
                                                numero={"Daviplata " + number}
                                                referencia={refPayco}
                                                fecha={moment(date).format("DD/MM/YYYY - HH:mm:ss")}
                                                costo={"$ " + comision}
                                                transaccion={state}
                                                cus={cus}
                                                motivo={"Recarga PSE"}
                                                nAprovacion={trxId}
                                                direcionIp={ip}
                                                docType={docType}
                                                docNumber={docNumber}
                                            />
                                        }
                                        fileName="DAV_Comprobante_RecargaPSE.pdf"
                                    >
                                        {({blob, url, loading, error}) => (loading ?
                                                <div className={"py-3 "}>
                                                    <b>Cargando documento...</b>
                                                </div>
                                                :
                                                <button
                                                    className={"py-2 text-left text-lg-left col-12 download px-0"}>
                                                    <i className={"icon-download mr-1"}> </i>
                                                    Descargar
                                                </button>
                                        )}
                                    </PDFDownloadLink>
                                </div>
                                }


                                <Flex className={"col-6 py-4 py-lg-0 justify-content-md-end"} flex={"1 0 150px"}>
                                    <Btn
                                        bRadius={"22px"}
                                        mw={"144px"}
                                        size={small ? "md" : "lg"}
                                        onClick={() => {
                                            removeCookies();
                                            history.push("/meter-plata")
                                            if (trx.state === "Aceptada") {
                                                sendDataToGTM({
                                                    eventCategory: 'Transaccion - Meter plata - Daviplata',
                                                    eventAction: 'Click',
                                                    eventLabel: 'Exitosa - Finalizar',
                                                    eventValue: '',
                                                    event: 'eventClick'
                                                })
                                            }
                                        }}
                                    > Finalizar </Btn>
                                </Flex>
                            </Flex>
                        </div>
                    </Fade>
                </div>
                <div className={"col-md-5 col-lg-5 d-none d-md-flex text-right px-0"}>
                    <img src="/img/imagen_resultado.png" alt="" className={"imgr ml-auto mr-0"}/>
                </div>
            </Flex>
        </Container>
    )
}

export default withRouter(React.memo(ResponseDetail));
